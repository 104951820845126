import escapeHTML from "escape-html";
import { getPathPrefix } from "utils/getPathPrefix";
import ContentCardTip from "./content-card-tip";
import type { ClassicCard } from "@braze/web-sdk";
import brazeSdk from "frontend/braze-sdk";

interface AttachToContentCardProps {
  contentCard: ClassicCard;
  onCtaClick: () => void;
  onDismiss: () => void;
}

export default function CanvasAnnouncementContentCard({
  contentCard,
  onCtaClick,
  onDismiss,
}: AttachToContentCardProps) {
  brazeSdk.useDismissContentCard(contentCard);
  brazeSdk.useLogCardImpressions(contentCard);

  const tip = {
    id: contentCard.id,
    title: contentCard.title ?? "",
    text: contentCard.description ?? "",
    image: getPathPrefix(escapeHTML(contentCard.imageUrl ?? "")),
    buttonTitle: contentCard.extras?.ctaText ?? "",
  };

  return (
    <div
      style={{
        position: "fixed",
        right: "45px",
        bottom: "85px",
      }}
    >
      <ContentCardTip tip={tip} onDismiss={onDismiss} onActionClick={onCtaClick} />
    </div>
  );
}
