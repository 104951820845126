import { inviteUserNew } from "frontend/api";
import { useState } from "react";
import { Permission, User, Account, Board } from "shared/datamodel/schemas";
import { useFeatureFlag } from "./use-feature-flag/use-feature-flag";
import { InstanceType } from "shared/datamodel/schemas/ability";
import { Project } from "shared/datamodel/schemas/project";
import { State, Team } from "shared/datamodel/schemas/team";
import type { BubbleFieldMutation, BubbleFieldValue } from "frontend/ui-components/form-fields/form-bubbles-field";
import { handleInviteEmailMutation, trackInviteCompletedAccount } from "utils/invite-utils";
import { useUsersCache } from "./caching/use-users-cache";
import { useInstanceMembers } from "./use-instance-members";

export default function useInviteNew({
  bulkPermission,
  instance,
  instanceType,
  source,
}: {
  bulkPermission: Permission;
  instance: Account | Team | Project | Board;
  instanceType: InstanceType;
  source: string;
}) {
  const isPerSeatLicenseEnabled = useFeatureFlag("per-seat-license");
  const [users, setUsers] = useState<User[]>([]);
  const [failed, setFailed] = useState<string[]>([]);
  const [addedSeats, setAddedSeats] = useState<number>(0);
  const [highlightInvalidEmails, setHighlightInvalidEmails] = useState(false);
  const [emails, setEmails] = useState<BubbleFieldValue[]>([]);
  const [loading, setLoading] = useState(false);
  const { updateUsersCache } = useUsersCache();
  const { updateMembers } = useInstanceMembers(
    instanceType === InstanceType.Board ? (instance as Board).documentId : instance.id.toString(),
    instanceType
  );
  const validEmails = emails.filter((v) => v.isValid).map((v) => v.value.toLowerCase());

  function getInstanceId() {
    switch (instanceType) {
      case InstanceType.Board: {
        return { documentId: (instance as Board).documentId };
      }
      case InstanceType.Project: {
        return { projectId: (instance as Project).id };
      }
      case InstanceType.Team: {
        return { teamId: (instance as Team).id };
      }
    }
  }

  async function handleInviteClick() {
    setLoading(true);
    await onInviteClick();
    setLoading(false);
  }

  function updateCaches(users: User[]) {
    updateUsersCache(users);
    switch (instanceType) {
      case InstanceType.Board: {
        return { documentId: (instance as Board).documentId };
      }
      case InstanceType.Project: {
        const newUsers = users.map((u) => ({
          id: u.id,
          state: State.active,
          permission: u.permission,
        }));
        const projectInstance = instance as Project;
        return updateMembers({
          ...projectInstance,
          userProjectPermissions: [...projectInstance.userProjectPermissions, ...newUsers],
        });
      }
      case InstanceType.Team: {
        const newUsers = users.map((u) => ({
          id: u.id,
          state: State.active,
          permission: u.permission,
        }));
        const teamInstance = instance as Team;
        return updateMembers({
          ...teamInstance,
          userTeamPermissions: [...teamInstance.userTeamPermissions, ...newUsers],
        });
      }
    }
  }

  async function onInviteClick() {
    const { users, failed, addedSeats, invitedToAccountAmount, invitedToAccountFailedAmount } = await inviteUserNew({
      emails: validEmails,
      bulkPermission,
      ...getInstanceId(),
      isPerSeatLicenseEnabled,
    });
    setUsers(users);
    updateCaches(users);
    setFailed(failed);
    setAddedSeats(addedSeats);
    trackInviteCompletedAccount(invitedToAccountAmount, invitedToAccountFailedAmount, source);
  }

  function changeEmails(bubbleFieldMutation: BubbleFieldMutation, source: string) {
    handleInviteEmailMutation(bubbleFieldMutation, source, setEmails, setHighlightInvalidEmails);
  }

  return {
    users,
    failed,
    addedSeats,
    onInviteClick,
    changeEmails,
    emails,
    highlightInvalidEmails,
    handleInviteClick,
    loading,
  };
}
