import { useFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";
import FormBubblesFieldV1 from "./form-bubbles-field-v1/form-bubbles-field-v1";
import FormBubblesFieldV2 from "./form-bubbles-field-v2/form-bubbles-field-v2";
import type { BubblesFieldProps } from "./types";

export type { BubbleFieldValue, BubbleFieldMutation } from "./types";

export default function FormBubblesField(props: BubblesFieldProps) {
  const isV2 = useFeatureFlag("input-bubbles-field-v2");

  return isV2 ? <FormBubblesFieldV2 {...props} /> : <FormBubblesFieldV1 {...props} />;
}
