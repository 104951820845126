import { useAtomValue, useSetAtom } from "jotai";
import { canvasAnnouncementContentCardAtom, contentCardsAtom } from "frontend/braze-sdk/atoms";
import AttachToContentCard from "./attach-to-content-card";
import CanvasAnnouncementContentCard from "./canvas-announcement-content-card";
import brazeSdk from "frontend/braze-sdk";
import tracking from "frontend/tracking";

interface InviteToBoardPopupProps {
  attachToRef?: React.RefObject<HTMLElement>;
  onClick: () => void;
}

export default function CanvasContentCard({ attachToRef, onClick }: InviteToBoardPopupProps) {
  const contentCard = useAtomValue(canvasAnnouncementContentCardAtom);
  const setContentCards = useSetAtom(contentCardsAtom);

  const onCtaClicked = () => {
    onClick();
    onDismiss();
    if (typeof contentCard?.extras?.eventName === "string") {
      tracking.trackAnalyticsEvent(`${contentCard?.extras?.eventName}_clicked`);
    }
  };

  const onDismiss = () => {
    brazeSdk.logCardDismissal(contentCard);
    setContentCards((previousCards) => previousCards.filter((card) => card.id !== contentCard?.id));
  };

  const onClose = () => {
    onDismiss();
    if (typeof contentCard?.extras?.eventName === "string") {
      tracking.trackAnalyticsEvent(`${contentCard?.extras?.eventName}_closed`);
    }
  };

  if (!contentCard) {
    return null;
  }

  if (attachToRef) {
    return (
      <AttachToContentCard
        contentCard={contentCard}
        attachToRef={attachToRef}
        onCtaClick={onCtaClicked}
        onClose={onClose}
        onDismiss={onDismiss}
      />
    );
  }

  return <CanvasAnnouncementContentCard contentCard={contentCard} onCtaClick={onCtaClicked} onDismiss={onClose} />;
}
