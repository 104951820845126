import { useEffect, useRef, useState } from "react";
import style from "./manage-collaboration-modal.module.css";
import Skeleton from "react-loading-skeleton";
import { User } from "shared/datamodel/schemas";
import useStateValue from "frontend/state/value";
import SendInviteToast from "frontend/ui-components/toast/send-invite-toast";
import useFeatureValue from "frontend/hooks/use-features";
import consts from "shared/consts";
import { useUsersCache } from "frontend/hooks/caching/use-users-cache";
import SearchEmptyState from "./empty-state/search-empty-state";
import { CollaborationMember } from "./collaboration-member";
import CollaborationHeader from "./collaboration-header";
import useInfiniteScroll from "frontend/hooks/use-infinite-scroll";
import { useDebounce } from "frontend/hooks/debounce";

export default function AccountMembers({
  title,
  onDismiss,
  onEditSeats,
}: {
  title: string;
  onDismiss: () => void;
  onEditSeats: () => void;
}) {
  const [{ user }] = useStateValue();
  const [members, setMembers] = useState<User[]>([] as User[]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [inviteeName, setInviteeName] = useState<string | null>(null);
  const collaborationModalRef = useRef(null);
  const { isLoading, getUsers, hasMore, searchUsersByName } = useUsersCache();

  const loadMoreUsersByPage = async () => {
    const loadedUsers = await getUsers();
    setMembers((previous) => {
      const uniqueUsers = [...previous, ...loadedUsers].reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {} as Record<string, User>);
      return Object.values(uniqueUsers);
    });
  };

  const loadMoreRef = useInfiniteScroll({
    loadMore: loadMoreUsersByPage,
    hasMore: hasMore && !debouncedSearchQuery,
    isLoading,
    failedLoadingUsers: false,
  });

  useEffect(() => {
    async function loadAccountUsers() {
      const loadedUsers = await getUsers();
      setMembers(loadedUsers);
    }
    loadAccountUsers();
  }, []);

  useEffect(() => {
    const performSearch = async () => {
      if (debouncedSearchQuery.length > 0) {
        const searchResults = searchUsersByName(debouncedSearchQuery);
        setMembers(searchResults);
      } else {
        const loadedUsers = await getUsers();
        setMembers(loadedUsers);
      }
    };
    performSearch();
  }, [debouncedSearchQuery]);

  const isFreePlan = !user || !user.planInfo || user.planInfo.is_free;
  const applyRestrictedLimitation = useFeatureValue(consts.FEATURE_NAMES.APPLY_VIEWER_RESTRICTED) === "true";
  const isTouchAccount = user?.planInfo?.source === "touch";
  const canManageSeats = !isFreePlan && !isTouchAccount && user.isAdmin && user.isSubscribedToCanvas;
  const isOwner = user?.isAdmin;

  useEffect(() => {
    let timeOut: NodeJS.Timeout;
    if (inviteeName) {
      timeOut = setTimeout(() => {
        setInviteeName(null);
      }, 6000);
    }
    return () => timeOut && clearTimeout(timeOut);
  }, [inviteeName]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredMembers = members.filter((member) => member.name.toLowerCase().includes(searchQuery.toLowerCase()));

  const renderMembers = () => {
    if (members.length > 0 && filteredMembers.length === 0 && !isLoading) {
      return <SearchEmptyState searchTerm={searchQuery} subtitle="in project members" />;
    }
    const sortedMembersBySubscription = !applyRestrictedLimitation
      ? filteredMembers
      : [...filteredMembers].sort((a, b) => {
          if (a.isSubscribedToCanvas && !b.isSubscribedToCanvas) return 1;
          if (!a.isSubscribedToCanvas && b.isSubscribedToCanvas) return -1;
          return 0;
        });

    return sortedMembersBySubscription?.map((teammate, index) => (
      <CollaborationMember
        key={teammate.id}
        teammate={teammate}
        members={members}
        setMembers={setMembers}
        index={index}
        isOwner={isOwner}
      />
    ));
  };

  return (
    <div className={style.container} ref={collaborationModalRef}>
      <div className={style.topContainer}>
        <CollaborationHeader
          title={title}
          onDismiss={() => {
            onDismiss();
          }}
        />
        <div className={style.search}>
          <div className={style.subtitleContainer}>
            <span className={style.subtitle}>{`Search a account member`}</span>
            {canManageSeats && (
              <span className={style.manageSeats} onClick={onEditSeats}>
                Manage seats
              </span>
            )}
          </div>
          <div className={style.searchContainer}>
            <input
              type="text"
              placeholder="Search members..."
              value={searchQuery}
              onChange={handleSearch}
              className={style.searchInput}
              autoFocus={true}
            />
          </div>
        </div>
      </div>
      <div className={style.divider}></div>
      <div className={style.collaborators}>
        {renderMembers()}
        <div ref={loadMoreRef}>
          {isLoading ? (
            <Skeleton count={4} className={style.userLineSkeleton} containerClassName={style.collaborators} />
          ) : null}
        </div>
      </div>
      {inviteeName && <SendInviteToast inviteeName={inviteeName} />}
    </div>
  );
}
