import { FormEvent, RefObject, useCallback, useEffect, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { getEmailsFromText, isEmail } from "utils/string-utils";
import { useTrackUserTyping } from "frontend/hooks/use-track-user-typing";
import type { BubbleFieldMutation, BubblesFieldProps } from "../types";

type EditingState = {
  index: number;
  value: string;
  cursorAtStart: boolean;
} | null;

export function useClipboardPasteListener(
  inputRef: RefObject<HTMLInputElement>,
  onChange: (value: BubbleFieldMutation) => void,
  setShowDropdown: (show: boolean) => void,
  existingValues: Set<string>
) {
  const handlePaste = useCallback(
    (e: ClipboardEvent) => {
      try {
        const text = e.clipboardData?.getData("text")?.toLowerCase();
        if (!text) return;

        e.preventDefault();

        // Use regex to match all email addresses in the text
        const uniqueValues = [...new Set(getEmailsFromText(text).filter((value) => !existingValues.has(value)))];

        for (const value of uniqueValues) {
          onChange({ value, action: "add" });
        }
        setShowDropdown(false);
      } catch {
        //
      }
    },
    [existingValues, onChange]
  );

  useEffect(() => {
    const input = inputRef.current;
    input?.addEventListener("paste", handlePaste);
    return () => {
      input?.removeEventListener("paste", handlePaste);
    };
  }, [handlePaste, inputRef]);
}

export function useFormBubblesFieldState(
  props: BubblesFieldProps,
  inputRef: RefObject<HTMLInputElement>,
  measureSpanRef: RefObject<HTMLSpanElement>
) {
  const { value: values, onChange, source, submitButtonRef, placeholder } = props;

  const [focusedTagIndex, setFocusedTagIndex] = useState<number | null>(null);
  const [editingState, setEditingState] = useState<EditingState>(null);
  const [isInputFieldFocused, setIsInputFieldFocused] = useState(false);
  const [inputWidth, setInputWidth] = useState(5);
  const [isEditingNewTag, setIsEditingNewTag] = useState(false);

  const trackUserTyping = useTrackUserTyping();

  function handleNavigationKeys(e: globalThis.KeyboardEvent, cursorAtStart: boolean) {
    if (!shouldHandleNavigation(e, cursorAtStart)) return;

    e.preventDefault();

    switch (e.key) {
      case "ArrowLeft": {
        handleArrowLeft();
        break;
      }
      case "ArrowRight": {
        handleArrowRight();
        break;
      }
      case "Enter": {
        if (focusedTagIndex !== null && focusedTagIndex < values.length) {
          const tagToEdit = values[focusedTagIndex];
          handleEdit(tagToEdit.value, focusedTagIndex);
        }
        break;
      }
      case "Backspace": {
        if (cursorAtStart && focusedTagIndex === null && values.length > 0) {
          // When backspace is pressed at start of input, focus last tag
          setFocusedTagIndex(values.length - 1);
        } else if (focusedTagIndex !== null && focusedTagIndex < values.length) {
          // When a tag is focused, enter edit mode
          const tagToEdit = values[focusedTagIndex];
          handleEdit(tagToEdit.value, focusedTagIndex);
        }
        break;
      }
      case "Delete": {
        handleTagDeletion();
        break;
      }
    }
  }

  function shouldHandleNavigation(e: globalThis.KeyboardEvent, cursorAtStart: boolean): boolean {
    const input = inputRef.current;
    const hasSelection = input && input.selectionStart !== input.selectionEnd;

    if (hasSelection) return false;
    if (e.key === "ArrowLeft" && !cursorAtStart) return false;
    if (e.key === "ArrowRight" && focusedTagIndex === null) return false;
    if (e.key === "Delete" && !cursorAtStart && focusedTagIndex === null) return false;
    if (e.key === "Backspace" && !cursorAtStart && focusedTagIndex === null) return false;
    if (e.key === "Enter" && focusedTagIndex === null) return false;

    return ["ArrowLeft", "ArrowRight", "Backspace", "Delete", "Enter"].includes(e.key);
  }

  function handleSpecialKeys(e: globalThis.KeyboardEvent, value: string): boolean {
    if (["Tab", "Enter", ","].includes(e.key) && value.length > 0) {
      e.preventDefault();

      // Check for duplicates before adding
      const isDuplicate = values.some((v) => v.value.toLowerCase() === value.toLowerCase());

      if (!isDuplicate) {
        onChange({ value, action: "add" });
      }

      if (inputRef.current) {
        inputRef.current.value = "";
      }
      return true;
    }

    // Focus submit button when pressing Enter with empty input
    if (e.key === "Enter" && value.length === 0) {
      e.preventDefault();
      inputRef.current?.blur();
      submitButtonRef?.current?.focus();
      return true;
    }

    return false;
  }

  function handleArrowLeft() {
    if (editingState === null) {
      // Regular navigation behavior
      const newIndex = Math.max(0, (focusedTagIndex ?? values.length) - 1);
      setFocusedTagIndex(newIndex);
      setIsInputFieldFocused(false);
      inputRef.current?.blur();

      // Start editing the tag we just moved to with cursor at end
      const tagToEdit = values[newIndex];
      if (tagToEdit) {
        handleEdit(tagToEdit.value, newIndex, false);
      }
    } else {
      // If currently editing, move to previous tag and start editing it
      const previousIndex = Math.max(0, editingState.index - 1);
      const previousValue = values[previousIndex];
      if (previousValue) {
        handleEdit(previousValue.value, previousIndex, false);
      }
    }
  }

  function handleArrowRight() {
    if (editingState !== null) {
      // If currently editing, move to next tag and start editing it
      const nextIndex = Math.min(values.length - 1, editingState.index + 1);
      if (nextIndex === editingState.index) {
        // If we're at the last tag, finish editing and focus input
        setEditingState(null);
        setFocusedTagIndex(null);
        setIsInputFieldFocused(true);
        inputRef.current?.focus();
      } else {
        // Otherwise edit next tag with cursor at start
        const nextValue = values[nextIndex];
        if (nextValue) {
          handleEdit(nextValue.value, nextIndex, true);
        }
      }
    } else if (focusedTagIndex !== null) {
      if (focusedTagIndex >= values.length - 1) {
        // If at last tag, move to input
        setFocusedTagIndex(null);
        setIsInputFieldFocused(true);
        inputRef.current?.focus();
      } else {
        // Move to and edit next tag with cursor at start
        const nextIndex = focusedTagIndex + 1;
        const nextValue = values[nextIndex];
        if (nextValue) {
          handleEdit(nextValue.value, nextIndex, true);
        }
      }
    }
  }

  function handleTagDeletion() {
    if (focusedTagIndex !== null && focusedTagIndex < values.length) {
      const deleteValue = values[focusedTagIndex];
      if (deleteValue) {
        handleDelete(deleteValue.value, deleteValue.id);
        updateFocusAfterDelete();
      }
    } else if (values.length > 0) {
      setFocusedTagIndex(values.length - 1);
    }
  }

  function updateFocusAfterDelete() {
    setFocusedTagIndex((previousIndex) => {
      if (!previousIndex || values.length <= 1) {
        // If it was the last tag, focus input
        setIsInputFieldFocused(true);
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
        return null;
      }
      if (previousIndex >= values.length - 1) return previousIndex - 1;
      return previousIndex;
    });
  }

  const handleKeydown = useCallback(
    (e: globalThis.KeyboardEvent) => {
      if (editingState !== null || !inputRef.current) return;

      const value = inputRef.current.value;
      const cursorAtStart = inputRef.current.selectionStart === 0;

      trackUserTyping(value, "email_invite_intent", {
        source: source || "email_multi_select",
      });

      if (handleSpecialKeys(e, value)) return;
      handleNavigationKeys(e, cursorAtStart);
    },
    [values, focusedTagIndex, editingState]
  );

  function onHandleCancelEditing() {
    setEditingState(null);
    setIsEditingNewTag(false);
    setFocusedTagIndex(null);
    setIsInputFieldFocused(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }

  function handleDelete(value: string, id: string) {
    // First clear any editing state
    setEditingState(null);
    setIsEditingNewTag(false);
    setFocusedTagIndex(null);

    // Then delete the tag
    onChange({ value, action: "delete", id });

    // Finally focus the input
    setIsInputFieldFocused(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }

  function handleEdit(value: string, index: number, cursorAtStart: boolean = false) {
    setIsEditingNewTag(true);
    setEditingState({
      index,
      value,
      cursorAtStart,
    });
    setFocusedTagIndex(index);
    setIsInputFieldFocused(false);
  }

  function handleEditComplete(
    id: string,
    originalValue: string,
    newValue: string,
    navigationKey?: "ArrowLeft" | "ArrowRight" | "Input"
  ) {
    const isDuplicate = values.some(
      (v) => v.value.toLowerCase() === newValue.toLowerCase() && v.value.toLowerCase() !== originalValue.toLowerCase()
    );

    if (isDuplicate) {
      onChange({ value: originalValue, action: "delete", id });
      setEditingState(null);
      if (!isEditingNewTag) {
        setIsInputFieldFocused(true);
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
      return;
    }

    if (!newValue) {
      onChange({ value: originalValue, action: "delete", id });
    } else if (originalValue !== newValue) {
      onChange({
        value: newValue,
        action: "edit",
        id,
      });
    }

    // Handle navigation after completing edit
    switch (navigationKey) {
      case "Input": {
        setEditingState(null);
        setFocusedTagIndex(null);
        setIsInputFieldFocused(true);
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);

        break;
      }
      case "ArrowRight": {
        const nextIndex = editingState ? editingState.index + 1 : 0;
        if (nextIndex < values.length) {
          // Move to next tag with cursor at start
          handleEdit(values[nextIndex].value, nextIndex, true);
        } else {
          // If at last tag, move to input
          setEditingState(null);
          setFocusedTagIndex(null);
          setIsInputFieldFocused(true);
          setTimeout(() => {
            inputRef.current?.focus();
          }, 0);
        }

        break;
      }
      case "ArrowLeft": {
        const previousIndex = editingState ? editingState.index - 1 : values.length - 1;
        if (previousIndex >= 0 && editingState?.index !== 0) {
          // Don't move if we're at the first tag
          // Move to previous tag with cursor at end
          handleEdit(values[previousIndex].value, previousIndex, false);
        } else {
          // Stay on first tag if we're already there
          if (editingState?.index === 0) {
            handleEdit(values[0].value, 0, false);
          }
        }

        break;
      }
      default: {
        setEditingState(null);
        if (!isEditingNewTag) {
          setIsInputFieldFocused(true);
          setTimeout(() => {
            inputRef.current?.focus();
          }, 0);
        }
      }
    }

    setIsEditingNewTag(false);
  }

  function handleFocus() {
    // Only clear editing state if we're actually editing
    if (editingState !== null) {
      setEditingState(null);
      setIsEditingNewTag(false);
    }
    setIsInputFieldFocused(true);
    setFocusedTagIndex(null);
  }

  function handleBlur() {
    setIsInputFieldFocused(false);
    inputRef.current?.blur();
  }

  const onInputChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      if (isEmail(value)) {
        // Check if value already exists before trying to add it
        const valueExists = values.some((v) => v.value.toLowerCase() === value.toLowerCase());
        if (!valueExists) {
          onChange({ value, action: "add" });
          e.currentTarget.value = "";

          setTimeout(() => {
            setEditingState({ index: values.length, value, cursorAtStart: false });
          }, 0);
        }
      }
    },
    [values]
  );

  useEffect(() => {
    if (focusedTagIndex !== null && values.length > 0) {
      inputRef.current?.blur();
    } else if (isInputFieldFocused) {
      inputRef?.current?.focus();
    }
  }, [focusedTagIndex, values]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeydown);
    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [handleKeydown]);

  const updateInputWidth = (text: string) => {
    if (measureSpanRef.current) {
      const contentToMeasure = text || (values.length === 0 ? placeholder : "") || "\u200B";
      measureSpanRef.current.textContent = contentToMeasure;
      const width = Math.max(10, measureSpanRef.current.offsetWidth + 2);
      setInputWidth(width);
    }
  };

  useEffect(() => {
    // Always update width based on current input value
    if (inputRef.current) {
      updateInputWidth(inputRef.current.value);
    } else {
      const contentToMeasure = values.length === 0 ? placeholder || "" : "";
      updateInputWidth(contentToMeasure);
    }
  }, [placeholder, values.length, editingState]);

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    onInputChange(e);
    // Always update width regardless of editing state
    updateInputWidth(e.currentTarget.value);
  };

  return {
    inputWidth,
    focusedTagIndex,
    editingState,
    isInputFieldFocused,
    handleFocus,
    handleBlur,
    handleDelete,
    handleEdit,
    handleEditComplete,
    handleInput,
    onHandleCancelEditing,
  };
}

export function useHandleClickOutside(
  containerRef: RefObject<HTMLDivElement>,
  inputRef: RefObject<HTMLInputElement>,
  dropdownRef: RefObject<HTMLDivElement>,
  onChange: (value: BubbleFieldMutation) => void
) {
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (dropdownRef?.current && dropdownRef.current.contains(e.target as Node)) {
        return;
      }

      const value = inputRef.current?.value;
      if (value) {
        onChange({ value, action: "add" });
        if (inputRef.current) {
          inputRef.current.value = "";
        }
      }
    },
    [onChange, inputRef, dropdownRef]
  );

  // Use the existing useOutsideRefs hook with both refs
  useOnClickOutside(containerRef, handleClickOutside);
}
