import type { BubbleFieldValue } from "../../types";
import { CloseIcon } from "frontend/icons/close";
import classNames from "classnames";
import style from "./tags.module.css";

type TagProps = {
  bubbleFieldValue: BubbleFieldValue;
  isFocused: boolean;
  highlightInvalid: boolean;
  rounded?: boolean;
  onDelete: (value: string, id: string) => void;
  onEdit: (value: string) => void;
};

export function Tag({ bubbleFieldValue, isFocused, highlightInvalid, rounded, onDelete, onEdit }: TagProps) {
  const { value, displayValue, isValid, id, thumbnail } = bubbleFieldValue;

  const tagClassname = classNames(style.tag, {
    [style.rounded]: rounded,
    [style.noThumbnail]: !thumbnail,
    [style.invalid]: !isValid,
    [style.focused]: isFocused,
    [style.highlight]: highlightInvalid,
  });

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onEdit(value);
  };

  const onDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete(value, id);
  };

  return (
    <div className={tagClassname} key={value} onClick={handleClick}>
      {!!thumbnail && <img src={thumbnail} alt={displayValue} className={style.thumbnail} />}
      <span>{displayValue}</span>
      <button className={style.closeButton} onClick={onDeleteClick} type="button">
        <CloseIcon className={style.closeIcon} color={!isValid && highlightInvalid ? "#F05C30" : "#848199"} />
      </button>
    </div>
  );
}
