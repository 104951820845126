import tracking from "frontend/tracking";
import type {
  BubbleFieldValue,
  BubbleFieldMutation,
} from "frontend/ui-components/form-fields/form-bubbles-field/types";
import consts from "shared/consts";
import { isEmail } from "./string-utils";
import type { SetStateAction } from "react";

export type InviteResponse = {
  usersCount: number;
  failed: string[];
  addedSeats: number;
};

export function trackInviteCompletedAccount(
  invitedToAccountAmount: number,
  invitedToAccountFailedAmount: number,
  source: string
) {
  if (invitedToAccountAmount > 0) {
    tracking.trackAnalyticsEvent("invite_completed_account", {
      invited_account: invitedToAccountAmount,
      invited_failed: invitedToAccountFailedAmount,
      source,
    });
  }
}

export function handleInviteEmailMutation(
  bubbleFieldMutation: BubbleFieldMutation,
  source: string,
  setEmails: (value: SetStateAction<BubbleFieldValue[]>) => void,
  setHighlightInvalidEmails: (highlightInvalidEmails: boolean) => void
) {
  const { value, action, id, thumbnail, displayValue } = bubbleFieldMutation;
  const isValid = isEmail(value);

  setHighlightInvalidEmails(false);
  switch (action) {
    case "add": {
      tracking.trackEvent(consts.TRACKING_CATEGORY.ADMIN_ACTION, "invite_added_email", source, isValid);
      const newValue: BubbleFieldValue = {
        id: id || Date.now().toString(),
        value,
        displayValue: displayValue || value,
        thumbnail,
        isValid,
      };
      setEmails((fieldValues) => [...fieldValues, newValue]);
      break;
    }
    case "edit": {
      tracking.trackAnalyticsEvent("invite_edited_email", {
        source,
        category: consts.TRACKING_CATEGORY.ADMIN_ACTION,
      });
      setEmails((fieldValues) => {
        return fieldValues.map((fieldValue) => {
          if (fieldValue.id !== id || fieldValue.value === value) {
            return fieldValue;
          }

          return {
            id: fieldValue.id,
            value,
            displayValue: value,
            isValid,
          };
        });
      });
      break;
    }
    case "delete": {
      tracking.trackEvent(consts.TRACKING_CATEGORY.ADMIN_ACTION, "invite_removed_email", source);
      setEmails((fieldValues) =>
        fieldValues.filter((fieldValue) => {
          if (id) {
            return fieldValue.id !== id;
          }
          return fieldValue.value !== value;
        })
      );
      break;
    }
  }
}
