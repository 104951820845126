import { useEffect, useRef, useState } from "react";
import { FieldProps, FormFieldContainer } from "./form-field-container";
import style from "./form-textarea-field.module.css";

type InputTextareaProps = FieldProps<string> & {
  height?: number;
  placeholder?: string;
  value?: string;
  customStyle?: React.CSSProperties;
};

export default function FormTextareaField(props: InputTextareaProps) {
  const {
    id,
    value,
    placeholder = "",
    onChange,
    onFocus = () => {},
    onBlur = () => {},
    autoFocus = false,
    tabIndex,
    height = 200,
    customStyle,
  } = props;
  const ref = useRef<any>(null);

  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    if (autoFocus) {
      ref && ref.current.focus();
    }
  }, []);

  function inputChanged(e: any) {
    onChange(e.target.value);
  }

  function focus(e: any) {
    setFocused(true);
    onFocus(e.target.id);
  }

  function blur(e: any) {
    setFocused(false);
    onBlur(e.target.id, e.target.value);
  }

  return (
    <FormFieldContainer {...props} isFocused={isFocused}>
      <textarea
        className={style.textarea}
        style={{ ...customStyle, height }}
        ref={ref}
        id={id}
        onChange={inputChanged}
        onFocus={focus}
        onBlur={blur}
        placeholder={placeholder}
        value={value}
        autoFocus={autoFocus}
        tabIndex={tabIndex}
      />
    </FormFieldContainer>
  );
}
