import UpgradeModalGeneric, { UpgradeModalNeededProps } from "./upgrade-modal";
import img from "public/images/upgrade-modal-images/upgrade-for-premium-shapes.png";

export default function UpgradeModalForPremiumShapes(props: UpgradeModalNeededProps) {
  return (
    <UpgradeModalGeneric
      trackingEvent="premium-shapes-upgrade-modal"
      title={"Upgrade to access all shape libraries"}
      content={
        "Create professional workflows with ease using dedicated diagramming libraries for Azure, AWS, Google Cloud and more."
      }
      bulletpoints={[
        "Blazing fast multiplayer",
        "Task management",
        "Commenting",
        "A world of templates",
        "Quality file export",
      ]}
      image={img.src}
      {...props}
    />
  );
}
