import { Floater } from "frontend/ui-components/floaters/floater";
import { DOMPlacement } from "frontend/ui-components/floaters/position-utils";
import { getPathPrefix } from "utils/getPathPrefix";
import { useEventListener } from "usehooks-ts";
import ContentCardTip from "./content-card-tip";
import type { ClassicCard } from "@braze/web-sdk";
import escapeHTML from "escape-html";
import brazeSdk from "frontend/braze-sdk";

interface AttachToContentCardProps {
  contentCard: ClassicCard;
  attachToRef: React.RefObject<HTMLElement>;
  onCtaClick: () => void;
  onDismiss: () => void;
  onClose: () => void;
}

export default function AttachToContentCard({
  contentCard,
  attachToRef,
  onCtaClick,
  onDismiss,
  onClose,
}: AttachToContentCardProps) {
  useEventListener("click", onDismiss, attachToRef);

  brazeSdk.useDismissContentCard(contentCard);
  brazeSdk.useLogCardImpressions(contentCard);
  brazeSdk.useTrackContentCardViewed(contentCard);

  const tip = {
    id: contentCard.id,
    title: contentCard.title ?? "",
    text: contentCard.description ?? "",
    image: getPathPrefix(escapeHTML(contentCard.imageUrl ?? "")),
    buttonTitle: contentCard.extras?.ctaText ?? "",
  };

  return (
    <Floater
      relativeTo={attachToRef}
      side="bottom"
      boundary="stage-container"
      boundaryPadding={40}
      extraStyle={{
        zIndex: Number.MAX_SAFE_INTEGER,
        transition: "opacity 0.1s ease-out, transform 0.1s ease-out",
        marginLeft: "-5px",
      }}
    >
      {(placement: DOMPlacement) => (
        <ContentCardTip tip={tip} placement={placement} onDismiss={onClose} onActionClick={onCtaClick} />
      )}
    </Floater>
  );
}
