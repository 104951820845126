import { isVideoCallUiOpenAtom } from "frontend/canvas-controls/video/video-atom";
import { useVideoCallsFeature } from "frontend/canvas-controls/video/video-hooks";
import { useBoardValue } from "frontend/state/board-state";
import { useCollaboratorsInVideoChat } from "frontend/subscriptions";
import { CloseIcon } from "frontend/ui-components/svg-shapes";
import { useAtom, useAtomValue } from "jotai";
import { useState } from "react";
import { syncServiceAtom } from "state-atoms";
import styles from "./video-call-ongoing.module.css";
import { isEmpty } from "rambda";

export const VideoCallOngoing = () => {
  const [isOpen, setIsOpen] = useState(true);
  const reflect = useAtomValue(syncServiceAtom)?.getReplicache();
  const [{ user }] = useBoardValue();
  const videoCallsFeature = useVideoCallsFeature();
  const [isVideoCallUiOpen, setIsVideoCallUiOpen] = useAtom(isVideoCallUiOpenAtom);
  const videoChat = useCollaboratorsInVideoChat(reflect);

  if (
    !videoChat ||
    isEmpty(videoChat) ||
    !isOpen ||
    user?.isAnonymous ||
    !videoCallsFeature?.enabled ||
    isVideoCallUiOpen
  ) {
    return null;
  }

  const names = videoChat.others?.map((o) => o.userInfo.name);

  if (!names) {
    return null;
  }

  const formattedNames = names.length > 3 ? `${names.join(", ").trimEnd().slice(0, -3)}...` : names.join(", ");

  return (
    <div className={styles.wrapper}>
      <CloseIcon
        color="#AFB3BF"
        onClick={() => setIsOpen(false)}
        style={{ position: "absolute", right: "13px", top: "13px", cursor: "pointer" }}
      />
      <div className={styles.titleWrapper}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
          <path
            d="M14.1758 8.64847V12.9218L17.4999 15.8276V5.82764L14.1758 8.64847Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.23917 5.82764H11.6392C12.8625 5.82764 14.1667 6.83014 14.1667 8.16347V14.9935C14.1687 15.2115 14.0844 15.4215 13.9322 15.5776C13.78 15.7337 13.5722 15.8233 13.3542 15.8268H4.71C3.32083 15.8268 2.5 14.5768 2.5 13.3268L2.50833 6.66014C2.49266 6.55485 2.50004 6.44741 2.52996 6.34525C2.55989 6.24309 2.61165 6.14866 2.68167 6.06847C2.75083 5.98928 2.83693 5.92666 2.93358 5.88526C3.03023 5.84386 3.13495 5.82473 3.24 5.8293L3.23917 5.82764Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className={styles.title}>Video call</div>
      </div>
      <div className={styles.contentWrapper}>
        <p className={styles.content}>Join video call with {formattedNames}</p>
        <button
          className={styles.join}
          onClick={() => {
            setIsVideoCallUiOpen(true);
            setIsOpen(false);
          }}
        >
          Join
        </button>
      </div>
    </div>
  );
};
