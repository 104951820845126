import { useEffect, useState } from "react";
import useModalDeepLink from "frontend/hooks/use-modal-deep-link";
import TemplatesWrapper from "./templates-wrapper";
import useStateValue from "frontend/state/value";
import Checkout from "frontend/billing/checkout";
import { RESET } from "jotai/utils";
import AppLoader from "frontend/loader/app-loader";

export type ModalAction = {
  type: string;
  payload: any;
} | null;

export const enum ModalType {
  /* eslint-disable @typescript-eslint/no-duplicate-enum-values */
  None = "none",
  Templates = "template",
  PreviewTemplate = "template",
  TemplateLibrary = "templateLibrary",
  Checkout = "checkout",
}

export default function ModalManager({
  setIsLoading,
  handleModalAction,
  onShowUpgradeModal,
  currentCanvasDocumentId,
  onCreateTemplate,
}: {
  setIsLoading: (isLoading: boolean) => void;
  handleModalAction: (action: ModalAction) => void;
  onShowUpgradeModal?: (value: string | null) => void;
  currentCanvasDocumentId?: string;
  onCreateTemplate?: () => void;
}) {
  const [{ user }] = useStateValue();
  const { modalTypeFromUrl, setModalDeepLink } = useModalDeepLink();
  const [currentModalType, setCurrentModalType] = useState<ModalType>(ModalType.None);

  useEffect(() => {
    if (modalTypeFromUrl?.toLowerCase().includes(ModalType.Templates)) {
      setCurrentModalType(ModalType.Templates);
    } else if (modalTypeFromUrl?.toLowerCase().includes(ModalType.Checkout)) {
      setCurrentModalType(ModalType.Checkout);
    }
  }, [modalTypeFromUrl]);

  const renderModal = () => {
    switch (currentModalType) {
      case ModalType.None: {
        return null;
      }

      case ModalType.Templates: {
        return (
          <TemplatesWrapper
            onShowUpgradeModal={onShowUpgradeModal}
            setIsLoading={setIsLoading}
            handleModalAction={handleModalAction}
            currentCanvasDocumentId={currentCanvasDocumentId}
            onCreateTemplate={onCreateTemplate}
          />
        );
      }
      case ModalType.Checkout: {
        if (user) {
          return (
            <Checkout
              user={user}
              onDismiss={() => {
                setModalDeepLink({ modalType: RESET });
                setCurrentModalType(ModalType.None);
              }}
            />
          );
        }
        return <AppLoader />;
      }
      default: {
        return null;
      }
    }
  };

  return renderModal();
}
