import { BoardMembers, BoardMemberCache } from "shared/datamodel/schemas/board";
import { editBoardMembers, getBoardMembers } from "frontend/api";
import { boardMemberCacheAtom } from "state-atoms";
import { useGenericCache } from "./use-general-cache";

export function useBoardMembersCache() {
  const { getItems, isLoading, updateItemCache } = useGenericCache<BoardMembers, BoardMemberCache, "documentId", null>({
    cacheAtom: boardMemberCacheAtom,
    fetchByIds: async (documentIds: string[]) => {
      const boards = await Promise.all(documentIds.map((documentId) => getBoardMembers(documentId)));
      return boards;
    },
    entityName: "boards",
    entityKey: "board",
    keyField: "documentId",
    updateItem: async (item: Partial<BoardMembers>) => {
      if (item.documentId && item.members) {
        await editBoardMembers({ documentId: item.documentId, members: item.members });
      }
      return item as BoardMembers;
    },
  });

  return { getBoardMembers: getItems, isLoading, updateBoardMembers: updateItemCache };
}
