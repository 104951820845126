import classNames from "classnames";
import SimpleBar from "simplebar-react";
import InviteUserCell from "./invite-user-cell";
import { User } from "shared/datamodel/schemas/user";
import style from "./invite-users-select.module.css";
import { useEffect } from "react";
import tracking from "frontend/tracking";

interface InviteUsersSelectProps {
  users: User[];
  usersDropdownRef: React.RefObject<HTMLDivElement>;
  inputValue: string;
  rounded?: boolean;
  handleUserClick: (user: User) => void;
  selectedIndex: number;
}

function InviteUsersSelect({
  usersDropdownRef,
  handleUserClick,
  rounded,
  selectedIndex,
  users,
}: InviteUsersSelectProps) {
  useEffect(() => {
    tracking.trackAnalyticsEvent("invite_users_select_opened");
  }, []);

  return (
    <div className={style.inviteUsersSelectContainer}>
      <SimpleBar
        className={classNames(style.usersContainer, { [style.rounded]: rounded })}
        scrollableNodeProps={{ ref: usersDropdownRef }}
      >
        {users.map((user, index) => (
          <InviteUserCell
            key={user.id}
            user={user}
            onClick={handleUserClick}
            rounded={rounded}
            isSelected={index === selectedIndex}
          />
        ))}
      </SimpleBar>
    </div>
  );
}

export default InviteUsersSelect;
