import tracking from "frontend/tracking";
import StyledButton, { ButtonSize } from "frontend/ui-components/styled-button";
import { useEffect } from "react";
import consts from "shared/consts";
import style from "./restricted-user-modal.module.css";
import Modal from "frontend/modal/modal";
import { useRestricted } from "frontend/hooks/use-restricted";
import AskToEditToast from "frontend/ui-components/toast/ask-to-edit-toast";
import { getPathPrefix } from "../utils/getPathPrefix";

const bulletpoints = [
  "Create canvases",
  "Edit canvases elements",
  "Real time collaboration",
  "A world of templates",
  "Quality file export",
];

const image = getPathPrefix("/images/restricted.png");

export function renderRestrictedUserContent(
  isAskToEditEnable: boolean,
  handleAskToEditClick: (source: string) => void,
  onDismiss?: () => void
) {
  return (
    <div className={style.centerScreen}>
      <div className={style.mainLayout}>
        {onDismiss && <button className={style.closeButton} onClick={onDismiss} data-testid="close-upgrade-modal" />}
        <div className={style.modal}>
          <img src={getPathPrefix("/images/logo.svg")} style={{ width: 125, height: 25 }} alt="WorkCanvas logo" />
          <div>
            <h1>{"You are a Viewer"}</h1>
            <h2>
              <span className={style.title}>{"Ask for edit permission"}</span>
            </h2>
          </div>
          <b>Explore all that WorkCanvas has to offer:</b>
          <ul>
            {bulletpoints.map((bulletpoint) => (
              <li key={bulletpoint}>&nbsp;&nbsp;{bulletpoint}</li>
            ))}
          </ul>
          <StyledButton
            title={isAskToEditEnable ? "Ask to edit" : "Request sent"}
            size={ButtonSize.regular}
            onClick={() => handleAskToEditClick("restricted-modal")}
            customStyle={{ alignSelf: "center", width: "100%", marginTop: "auto", fontSize: 16, fontWeight: 600 }}
            enabled={isAskToEditEnable}
          />
        </div>

        <div className={style.modalImage}>
          <img src={image} alt="Restricted user illustration" />
        </div>
      </div>
    </div>
  );
}

export default function RestrictedUserModal({
  trackingEvent,
  onDismiss,
}: {
  trackingEvent: string;
  onDismiss: () => void;
}) {
  const { isAskToEditEnable, handleAskToEditClick, showAskToEditToast } = useRestricted();
  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.RESTRICTED_ACTION, "restricted-modal-shown", trackingEvent);
  }, []);

  return (
    <>
      <Modal dimBackground={true} onClickBackground={onDismiss} onDismissOnEscape={onDismiss}>
        {renderRestrictedUserContent(isAskToEditEnable, handleAskToEditClick, onDismiss)}
        {showAskToEditToast && <AskToEditToast />}
      </Modal>
    </>
  );
}
