import React from "react";

export const LogoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
      <path
        d="M9.5 4V3C9.5 1.89543 8.60457 1 7.5 1H3C1.89543 1 1 1.89543 1 3V11C1 12.1046 1.89543 13 3 13H7.5C8.60457 13 9.5 12.1046 9.5 11V9.5"
        stroke="#113357"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path d="M7 7H14" stroke="#113357" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 9L14 7" stroke="#113357" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 5L14 7" stroke="#113357" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
