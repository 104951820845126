import { MondayUserBoardPermissions } from "frontend/services/monday-api-service";
import useStateValue from "frontend/state/value";
import { getUserRole, UserRole } from "shared/datamodel/schemas/ability";
import { MondayUserRole, User } from "shared/datamodel/schemas/user";

export default function useMondayAbility() {
  const [{ mondayBoardAccessPermission }] = useStateValue();

  function canEditMondayInstance(user: User | MondayUserRole, applyRestrictedLimitation: boolean) {
    const userRole = getUserRole(user, applyRestrictedLimitation);
    // Guests are not restricted in monday canvas
    if (!user.isGuest && userRole === UserRole.Restricted) {
      return false;
    }
    return mondayBoardAccessPermission === MondayUserBoardPermissions.edit ? true : false;
  }

  function canViewMondayInstance() {
    return mondayBoardAccessPermission === MondayUserBoardPermissions.view ? true : false;
  }

  function canInviteToMondayInstance() {
    return false;
  }

  function canShareMondayBoard() {
    return false;
  }

  function canPerformAnyActionInMonday(user: User | MondayUserRole, applyRestrictedLimitation: boolean) {
    if (applyRestrictedLimitation && !user.isSubscribedToCanvas && !user.isGuest) {
      return false;
    }
    return true;
  }

  return {
    canEditMondayInstance,
    canViewMondayInstance,
    canInviteToMondayInstance,
    canShareMondayBoard,
    canPerformAnyActionInMonday,
  };
}
