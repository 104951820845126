import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import style from "./optionsContextMenu.module.css";
import useOutsideRef from "frontend/utils/click-outside-handler";
import cn from "classnames";
import { Floater } from "frontend/ui-components/floaters/floater";
import { getPathPrefix } from "../utils/getPathPrefix";

export type ContextMenuOption = {
  title: string;
  id: string | null;
  onClick: (e: any) => void;
  iconPath?: ReactNode | string | null;
  isSeparator?: boolean;
  disabled?: boolean;
  isHighlighted?: boolean;
  requireUpgrade?: boolean;
  onUpgeadeClicked?: () => void;
  icon?: JSX.Element;
  submenuItems?: ContextMenuOption[];
};

export function createMenuOption({
  title,
  iconPath,
  icon,
  id,
  onClick,
  disabled = false,
  requireUpgrade = false,
  onUpgeadeClicked,
  isHighlighted = false,
  submenuItems,
}: {
  title: string;
  iconPath?: ReactNode | string | null;
  icon?: JSX.Element;
  id: string;
  onClick: (e: any) => void;
  disabled?: boolean;
  requireUpgrade?: boolean;
  onUpgeadeClicked?: () => void;
  isHighlighted?: boolean;
  submenuItems?: ContextMenuOption[];
}): ContextMenuOption {
  return {
    title,
    iconPath,
    id,
    onClick,
    disabled,
    requireUpgrade,
    onUpgeadeClicked,
    isHighlighted,
    submenuItems,
    icon,
  };
}

export function createMenuSeparator(): ContextMenuOption {
  return { title: "", iconPath: "", onClick: () => {}, id: null, isSeparator: true };
}

function RequiredUpgradeMenuOption({ option }: { option: ContextMenuOption }) {
  return (
    <div
      style={{
        filter: "unset",
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        cursor: "pointer",
        alignItems: "center",
      }}
      data-testid={option.id}
      data-id={option.id}
      data-upgrade={"true"}
      onClick={option.onClick}
    >
      <img width={17} height={17} src={getPathPrefix("/images/golden-crown.svg")} />
      <span className={cn(style.optionTitle, style.line)}>{option.title}</span>
    </div>
  );
}

export function MenuOption({ option, isSelected = false }: { option: ContextMenuOption; isSelected?: boolean }) {
  const ref = useRef<any>(null);
  if (option.requireUpgrade) return <RequiredUpgradeMenuOption option={option} />;

  let icon: ReactNode = <span className={style.emptySpace} />;
  if (option.icon) {
    icon = option.icon;
  } else if (option.iconPath) {
    if (typeof option.iconPath === "object") {
      icon = option.iconPath;
    } else {
      icon = <img src={option.iconPath.toString()} width="17" height="17" />;
    }
  }

  return (
    <>
      <div
        ref={ref}
        className={cn(style.line, { [style.disabled]: option.disabled, [style.highlighted]: option.isHighlighted })}
        data-testid={option.id}
        onClick={option.disabled ? undefined : option.onClick}
        data-id={option.id}
      >
        <div className={style.iconContainer}>{icon}</div>
        <span className={style.optionTitle}>{option.title}</span>
      </div>
      {isSelected && option.submenuItems && (
        <Floater
          side="right"
          margin={10}
          relativeTo={ref}
          className={cn(style.container, style.light)}
          useContainerForPositioning
          extraStyle={{ position: "absolute" }}
        >
          <div className={style.body} onContextMenu={(e) => e.preventDefault()}>
            {option.submenuItems.map((item, index) => (
              <MenuOption key={index} option={item} isSelected={false} />
            ))}
          </div>
        </Floater>
      )}
    </>
  );
}

export function OptionsContextMenu({
  options,
  attachToRef = null,
  position = null,
  onDismiss,
  customStyle = null,
  portalID = undefined,
}: {
  options: Array<ContextMenuOption>;
  attachToRef?: any;
  position?: { x: number; y: number } | null;
  onDismiss: () => void;
  customStyle?: any;
  portalID?: string;
}) {
  const [rect, setRect] = useState({ width: 0, height: 0, left: 0, top: 0 });
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [arrowPosition, setArrowPosition] = useState(0);
  const ref = useRef(null) as any;

  useEffect(() => {
    window.addEventListener("resize", resized);
    resized();
    return () => {
      window.removeEventListener("resize", resized);
    };
  }, []);

  useLayoutEffect(() => {
    if (ref && ref.current && attachToRef) {
      const refRect = ref.current.getBoundingClientRect();
      const desiredLeftPosition = rect.left + (rect.width - refRect.width) / 2;
      const desiredArrowPosition = (refRect.width - 10) / 2;
      const position = {
        x: Math.max(
          0,
          Math.min(
            window.innerWidth - refRect.width - 20, // padding
            rect.left + (rect.width - refRect.width) / 2
          )
        ),
        y: rect.top + rect.height,
      };
      setMenuPosition(position);
      const arrowPosition = desiredArrowPosition - (position.x - desiredLeftPosition);
      setArrowPosition(arrowPosition);
    }
  }, [rect, ref]);

  useOutsideRef(ref, onDismiss);

  function resized() {
    if (attachToRef && attachToRef.current) {
      const refRect = attachToRef.current.getBoundingClientRect();
      const rect = {
        top: refRect.top,
        left: refRect.left,
        width: refRect.width,
        height: refRect.height,
      };
      setRect(rect);
    }
  }

  function renderOptions() {
    return options.map((option, index) => {
      const { isSeparator = false } = option;
      if (isSeparator) {
        return <hr className={style.separator} key={index} />;
      }
      return <MenuOption option={option} key={index} />;
    });
  }
  const xPos = position?.x || menuPosition.x;
  const yPos = (position?.y || menuPosition.y) + (attachToRef ? 8 : 0);

  const menuContent = (
    <div
      className={style.container}
      style={{
        position: "absolute",
        left: `${xPos}px`,
        top: `${yPos}px`,
      }}
      ref={ref}
    >
      {attachToRef && (
        <div className={style.arrowContainer} style={{ left: `${arrowPosition}px` }}>
          <div className={style.arrow} />
        </div>
      )}
      <div className={style.body} style={customStyle}>
        {renderOptions()}
      </div>
    </div>
  );

  if (portalID) {
    const portalElement = document.getElementById(portalID);
    if (!portalElement) {
      console.warn(`Portal element with ID "${portalID}" not found. Rendering in-place.`);
      return menuContent;
    }
    return ReactDOM.createPortal(menuContent, portalElement);
  }

  return menuContent;
}
