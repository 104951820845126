import StyledButton, { ButtonSize, ButtonStyle } from "frontend/ui-components/styled-button";
import style from "./upgrade-modal.module.css";
import { getPathPrefix } from "utils/getPathPrefix";
import { Crown } from "frontend/icons/crown";

type UpgradeModalContentProps = {
  title: React.ReactNode;
  mainTitle?: string;
  content: string;
  bulletpoints: string[];
  image: string;
  imageClassName?: string;
  showCancelLine?: boolean;
  cancelLineText?: React.ReactNode;
  upgradeButtonStyle?: ButtonStyle;
  buttonText: string;
  onDismiss: () => void;
  onUpgrade: () => void;
  showDismissButton?: boolean;
  replaceTitleWith?: React.ReactNode;
};

export default function UpgradeModalContent({
  title,
  mainTitle,
  content,
  bulletpoints,
  image,
  imageClassName,
  showCancelLine,
  cancelLineText,
  upgradeButtonStyle,
  buttonText,
  onDismiss,
  onUpgrade,
  showDismissButton,
  replaceTitleWith,
}: UpgradeModalContentProps) {
  return (
    <div className={style.centerScreen}>
      <div className={style.mainLayout}>
        <button className={style.closeButton} onClick={onDismiss} data-testid="close-upgrade-modal" />
        <div className={style.upgradeModal}>
          <h2>
            {replaceTitleWith || (
              <>
                <Crown />
                <span className={style.title}>{title}</span>
              </>
            )}
          </h2>
          <h1>{mainTitle}</h1>
          <section dangerouslySetInnerHTML={{ __html: content }} />{" "}
          {/* enable to add bold to the content: `dangerouslySetInnerHTML={{__html:` */}
          <b>Explore all that WorkCanvas has to offer:</b>
          <ul>
            {bulletpoints.map((bulletpoint) => (
              <li key={bulletpoint}>&nbsp;&nbsp;{bulletpoint}</li>
            ))}
          </ul>
          {showCancelLine && (
            <>
              <div className={style.hline} />
              <span className={style.cancelContainer}>
                {cancelLineText || (
                  <>
                    You may <b>Cancel your trial</b> any time
                  </>
                )}
              </span>
              <div className={style.hline} />
            </>
          )}
          <div data-testid="upgrade-cta-on-upgrade-modal" className={style.upgradeButton}>
            <StyledButton
              title={buttonText}
              icon={getPathPrefix("/images/crown-gold.svg")}
              style={upgradeButtonStyle}
              size={ButtonSize.regular}
              onClick={onUpgrade}
              customStyle={{
                alignSelf: "center",
                width: "100%",
                marginTop: "auto",
                borderRadius: "6px",
                height: "45px",
              }}
            />
          </div>
          {showDismissButton && (
            <StyledButton
              title={`continue`}
              style={ButtonStyle.outline}
              size={ButtonSize.regular}
              onClick={onDismiss}
              customStyle={{ alignSelf: "center", width: "100%", marginTop: "16px" }}
            />
          )}
        </div>

        <div className={imageClassName || style.upgradeImage}>
          <img src={image} alt="Upgrade" />
        </div>
      </div>
    </div>
  );
}
