import React, { forwardRef, useRef } from "react";
import IconBase, { IconProps } from "./icon-base";
import { useHover } from "frontend/ui-components/floaters/use-hover";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";

type VisibleCursorsProps = IconProps & { tooltip?: boolean };

const VisibleCursors = forwardRef<SVGSVGElement, VisibleCursorsProps>((props, ref) => {
  const hoverRef = useRef<any>(null);
  const hover = useHover(hoverRef);
  const { tooltip = false, ...iconProps } = props;

  return (
    <div data-testid="visibleCursorIcon" ref={hoverRef} style={{ display: "flex", justifyContent: "center" }}>
      <IconBase
        ref={ref}
        fill="none"
        renderPath={() => {
          return (
            <path
              d="M1.03248 2.29739C0.771894 1.51563 1.51563 0.771894 2.29739 1.03248L14.4431 5.08104C15.2883 5.36277 15.3709 6.52573 14.5741 6.92415L9.77226 9.32505C9.57874 9.42181 9.42181 9.57874 9.32505 9.77226L6.92415 14.5741C6.52573 15.3709 5.36277 15.2883 5.08104 14.4431L1.03248 2.29739Z"
              stroke="currentColor"
              strokeLinecap="round"
            />
          );
        }}
        viewBox="0 0 17 17"
        {...iconProps}
      >
        {props.children}
      </IconBase>
      {hover && tooltip && <Tooltip label={"Visible cursors"} relativeTo={hoverRef}></Tooltip>}
    </div>
  );
});

VisibleCursors.displayName = "VisibleCursors";

export { VisibleCursors };
