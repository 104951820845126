import { useCallback, useState } from "react";
import useSWR from "swr";
import { InstanceType } from "shared/datamodel/schemas/ability";
import { Board, BoardCache, MinimalBoard } from "shared/datamodel/schemas";
import { useGenericCache } from "./use-general-cache";
import {
  boardCacheAtom,
  MY_BOARDS_SECTION_ID,
  RECENT_BOARDS_SECTION_ID,
  SHARED_WITH_ME_SECTION_ID,
} from "state-atoms/boards-atom";
import { useBoardsSections } from "./use-boards-sections";
import { fillBoardContent } from "utils/replicache-utils";
import {
  getRecentBoards,
  updateBoardAccess,
  fetchBoard,
  getBoardsByInstance,
  getMyBoards,
  deleteBoard,
  editBoard,
  createBoardNew,
  duplicateBoard as internalDuplicateBoard,
  moveBoard as internalMoveBoard,
  getSharedWithMeBoards,
} from "frontend/api";
import { BoardSectionType, getSectionId } from "shared/datamodel/schemas/board-section";

async function fetchBoardsById(id: string) {
  const result = await fetchBoard(id);
  return result.board;
}

export function useBoardsCache() {
  const {
    getSection,
    updateSection,
    addBoard,
    removeBoard: removeFromSections,
    updateBoard: updateInSections,
    isSectionInCache,
  } = useBoardsSections();

  const { getItem, isLoading, updateCache, createItemCache, removeItemCache, updateItemCache, getValidCachedItems } =
    useGenericCache<MinimalBoard, BoardCache, "documentId", Partial<Board>>({
      cacheAtom: boardCacheAtom,
      fetchById: fetchBoardsById,
      entityName: "boards",
      entityKey: "board",
      keyField: "documentId",
      createItem: async (item: Partial<Board>) => {
        const board = await createBoardNew({ board: item, isPrivateByCreator: true });
        const sectionIds: string[] = [RECENT_BOARDS_SECTION_ID, MY_BOARDS_SECTION_ID];
        const projectId = item.projectId;
        const teamId = item.teamId;
        if (projectId) sectionIds.push(getSectionId(BoardSectionType.PROJECT, projectId));
        if (teamId) sectionIds.push(getSectionId(BoardSectionType.TEAM, teamId));
        addBoard(board, sectionIds);
        return board;
      },
      removeItem: async (item: Partial<MinimalBoard>) => {
        if (item.documentId) {
          removeFromSections(item.documentId);
          await deleteBoard(item.documentId);
          return true;
        }
        return false;
      },
      updateItem: async (item: Partial<MinimalBoard>) => {
        if (item.documentId) {
          const updatedBoard = item as MinimalBoard;
          updateInSections(updatedBoard);
          await editBoard(item as Board);
          return updatedBoard;
        }
        throw new Error("Board documentId is required for update");
      },
    });

  const duplicateBoard = async ({
    documentId,
    userId,
    userRepsToken,
  }: {
    documentId: string;
    userId: string;
    userRepsToken: string;
  }) => {
    const { board: newBoard, contents } = await internalDuplicateBoard(documentId);
    await fillBoardContent(userId, newBoard.documentId, userRepsToken, contents);
    updateCache([newBoard]);
    const sectionIds: string[] = [RECENT_BOARDS_SECTION_ID, MY_BOARDS_SECTION_ID];
    const projectId = newBoard.projectId;
    const teamId = newBoard.teamId;
    if (projectId) sectionIds.push(getSectionId(BoardSectionType.PROJECT, projectId));
    if (teamId) sectionIds.push(getSectionId(BoardSectionType.TEAM, teamId));
    addBoard(newBoard, sectionIds);
    return { documentId: newBoard.documentId, contents };
  };

  const moveBoard = async (board: Board, oldBoard: Board) => {
    const oldSectionIds: string[] = [];
    if (oldBoard.projectId) oldSectionIds.push(getSectionId(BoardSectionType.PROJECT, oldBoard.projectId));
    else if (oldBoard.teamId) oldSectionIds.push(getSectionId(BoardSectionType.TEAM, oldBoard.teamId));
    else oldSectionIds.push(MY_BOARDS_SECTION_ID);
    removeFromSections(oldBoard.documentId, oldSectionIds);
    await internalMoveBoard(board);
    updateItemCache(board);
    const newSectionId = board.projectId
      ? getSectionId(BoardSectionType.PROJECT, board.projectId)
      : board.teamId
      ? getSectionId(BoardSectionType.TEAM, board.teamId)
      : MY_BOARDS_SECTION_ID;
    addBoard(board, [newSectionId]);
    return board;
  };

  const [shouldFetchRecent, setShouldFetchRecent] = useState(false);
  const [shouldFetchMyBoards, setShouldFetchMyBoards] = useState(false);
  const [shouldFetchSharedWithMe, setShouldFetchSharedWithMe] = useState(false);
  const [fetchByInstance, setFetchByInstance] = useState<{
    instanceId: number;
    instanceType: InstanceType;
  } | null>(null);

  const { isLoading: isLoadingRecent } = useSWR(
    shouldFetchRecent ? "recent-boards" : null,
    async () => {
      const boards = await getRecentBoards();
      updateCache(boards);
      updateSection(RECENT_BOARDS_SECTION_ID, boards);
      return boards;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      suspense: false,
      disabled: true,
    }
  );

  const fetchRecentBoards = useCallback(() => {
    setShouldFetchRecent(true);
  }, []);

  const { isLoading: isLoadingMyBoards } = useSWR(
    shouldFetchMyBoards ? "my-boards" : null,
    async () => {
      const boards = await getMyBoards();
      updateCache(boards);
      updateSection(MY_BOARDS_SECTION_ID, boards);
      return boards;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      suspense: false,
    }
  );

  const fetchMyBoards = useCallback(() => {
    setShouldFetchMyBoards(true);
  }, []);

  const { isLoading: isLoadingSharedWithMe } = useSWR(
    shouldFetchSharedWithMe ? "shared-with-me" : null,
    async () => {
      const boards = await getSharedWithMeBoards();
      updateCache(boards);
      updateSection(SHARED_WITH_ME_SECTION_ID, boards);
      return boards;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      suspense: false,
    }
  );

  const fetchSharedWithMeBoards = useCallback(() => {
    setShouldFetchSharedWithMe(true);
  }, []);

  const { isLoading: isLoadingByInstance } = useSWR(
    fetchByInstance ? ["boards-by-instance", fetchByInstance.instanceId, fetchByInstance.instanceType] : null,
    async () => {
      if (!fetchByInstance) return [];
      const { instanceId, instanceType } = fetchByInstance;
      const sectionId = `${instanceType}-${instanceId}`;
      const sectionInCache = isSectionInCache(sectionId);
      if (sectionInCache) {
        return getSection(sectionId);
      }
      const boards = await getBoardsByInstance({ instanceId, instanceType });
      updateCache(boards);
      updateSection(sectionId, boards);
      setFetchByInstance(null);
      return boards;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      suspense: false,
    }
  );

  const fetchBoardsByInstance = useCallback(
    ({ instanceId, instanceType }: { instanceId: number; instanceType: InstanceType }) => {
      setFetchByInstance({ instanceId, instanceType });
    },
    []
  );

  const accessBoard = useCallback(
    (board: Board) => {
      updateCache([board]);
      updateBoardAccess(board.documentId);
      return board;
    },
    [updateCache]
  );

  return {
    getBoard: getItem,
    isLoading: isLoading || isLoadingRecent || isLoadingMyBoards || isLoadingByInstance || isLoadingSharedWithMe,
    getSection,
    fetchRecentBoards,
    fetchMyBoards,
    fetchSharedWithMeBoards,
    fetchBoardsByInstance,
    accessBoard,
    createBoard: createItemCache,
    removeBoard: removeItemCache,
    updateBoard: updateItemCache,
    duplicateBoard,
    moveBoard,
    getAllBoards: getValidCachedItems,
  };
}
