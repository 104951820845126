import { User, Board, BoardPermission, Permission, BoardWithMembers } from "shared/datamodel/schemas";
import {
  InstanceType,
  instanceActionsByRole,
  InstanceAction,
  getUserAndInstanceRole,
  UserRole,
} from "shared/datamodel/schemas/ability";
import { Project } from "shared/datamodel/schemas/project";
import { Team } from "shared/datamodel/schemas/team";
import useFeatureValue from "./use-features";
import { z } from "zod";
import consts from "shared/consts";
import useMondayAbility from "frontend/hooks/use-monday-abillity";
import { useAtomValue } from "jotai";
import { isInIframeAtom } from "state-atoms/general-atoms";
import * as Sentry from "@sentry/nextjs";

export default function useAbility() {
  const isInMondayAppIframe = useAtomValue(isInIframeAtom);
  const applyRestrictedLimitation = useFeatureValue(consts.FEATURE_NAMES.APPLY_VIEWER_RESTRICTED) === "true";
  const {
    canEditMondayInstance,
    canViewMondayInstance,
    canInviteToMondayInstance,
    canShareMondayBoard,
    canPerformAnyActionInMonday,
  } = useMondayAbility(); // In case of monday canvas(inside of iframe) we want to enforce monday user board permission instead of our own permission system
  function canCreateInstance(user: User, instance?: Team | Project | Board, type?: InstanceType) {
    const { userRole, instanceRole } = getUserAndInstanceRole({ user, instance, type, applyRestrictedLimitation });
    return instanceActionsByRole[userRole][instanceRole].has(InstanceAction.Create);
  }

  function canEditInstance(user: User | null, instance: Team | Project | Board, type: InstanceType) {
    if (type === InstanceType.Board && isInMondayAppIframe) {
      if (!user) return false;
      return canEditMondayInstance(user, applyRestrictedLimitation);
    }
    if (type === InstanceType.Board && !(instance as Board).hasMemberAccess) {
      return instance.permission === BoardPermission.public;
    }
    if (type === InstanceType.Board && instance.permission === BoardPermission.public) {
      return true;
    }
    if (!user) return false;
    const { userRole, instanceRole } = getUserAndInstanceRole({ user, instance, type, applyRestrictedLimitation });
    return instanceActionsByRole[userRole][instanceRole].has(InstanceAction.Edit);
  }

  function canViewInstance(user: User, instance: Team | Project | Board, type: InstanceType) {
    if (type === InstanceType.Board && isInMondayAppIframe) {
      return canViewMondayInstance();
    }
    const { userRole, instanceRole } = getUserAndInstanceRole({ user, instance, type, applyRestrictedLimitation });
    return instanceActionsByRole[userRole][instanceRole].has(InstanceAction.View);
  }

  function canInviteToInstance(user: User | null, instance: Team | Project | Board, type: InstanceType) {
    if (type === InstanceType.Board && isInMondayAppIframe) {
      return canInviteToMondayInstance();
    }
    if (!user) return false;
    const { userRole, instanceRole } = getUserAndInstanceRole({ user, instance, type, applyRestrictedLimitation });
    return instanceActionsByRole[userRole][instanceRole].has(InstanceAction.Invite);
  }

  function canShareBoard(user: User, instance: Board) {
    if (user.accountId !== instance.accountId) {
      return false;
    }
    if (isInMondayAppIframe) {
      return canShareMondayBoard();
    }
    const { userRole, instanceRole } = getUserAndInstanceRole({
      user,
      instance,
      type: InstanceType.Board,
      applyRestrictedLimitation,
    });
    return instanceActionsByRole[userRole][instanceRole].has(InstanceAction.Share);
  }

  function canPerformAnyAction(user: User | null, instance: Team | Project | Board, type: InstanceType) {
    if (isInMondayAppIframe) {
      return user && canPerformAnyActionInMonday(user, applyRestrictedLimitation);
    }
    if (type === InstanceType.Board && !(instance as Board).hasMemberAccess) {
      return instance.permission !== BoardPermission.private;
    }
    if (type === InstanceType.Board && instance.permission === BoardPermission.public) {
      return true;
    }
    if (!user) return false;
    const { userRole, instanceRole } = getUserAndInstanceRole({ user, instance, type, applyRestrictedLimitation });
    return instanceActionsByRole[userRole][instanceRole].size > 0;
  }

  function canPerformAnyActionNew(user: User, instance: Team | Project | Board, type: InstanceType) {
    const { userRole, instanceRole } = getUserAndInstanceRole({ user, instance, type, applyRestrictedLimitation });
    if (userRole === UserRole.Restricted) {
      return false;
    }
    if (type === InstanceType.Board && instance.permission === BoardPermission.public) {
      return true;
    }
    if (type === InstanceType.Board && !(instance as Board).hasMemberAccess) {
      return instance.permission !== BoardPermission.private;
    }
    return instanceActionsByRole[userRole][instanceRole].size > 0;
  }

  function canMemberEditInstance(member: User, instance: Team | Project | BoardWithMembers, type: InstanceType) {
    const { userRole } = getUserAndInstanceRole({ user: member, instance, type, applyRestrictedLimitation });

    if (userRole === UserRole.Restricted) {
      return false;
    }

    try {
      switch (type) {
        case InstanceType.Board: {
          const board = instance as BoardWithMembers;
          return (
            board.members.find((boardMember) => boardMember.id.toString() === member.id)?.permission ===
            Permission.editor
          );
        }

        case InstanceType.Team: {
          const team = instance as Team;
          return team.userTeamPermissions.find((user) => user.id === member.id)?.permission === Permission.editor;
        }

        case InstanceType.Project: {
          const project = instance as Project;
          return (
            project.userProjectPermissions.find((user) => user.id.toString() === member.id)?.permission ===
            Permission.editor
          );
        }

        default: {
          return false;
        }
      }
    } catch (error) {
      if (error instanceof z.ZodError) {
        Sentry.captureException(error, { tags: { type: "ability" } });
      }
      return false;
    }
  }

  return {
    canCreateInstance,
    canEditInstance,
    canViewInstance,
    canInviteToInstance,
    canShareBoard,
    canPerformAnyAction,
    canMemberEditInstance,
    canPerformAnyActionNew,
  };
}
