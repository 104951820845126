import React, { useEffect, useState } from "react";
import { getPlansData, triggerAction } from "frontend/api";
import { CheckoutSource } from "frontend/billing/checkout";
import PaymentModal from "frontend/billing/payment-modal";
import PaymentPage from "frontend/billing/payment-page";
import { stringToPlan } from "frontend/billingUtils";
import tracking from "frontend/tracking";
import { getPriceByPlanMapping } from "frontend/utils/billing-utils";
import consts, { Plan } from "shared/consts";
import { User } from "shared/datamodel/schemas/user";
import { isTrialActive } from "shared/util/billing";
import { plansDataAtom } from "state-atoms/billing-atoms";
import useSeatsCount from "frontend/hooks/use-seats-count";
import { PlanData } from "shared/datamodel/schemas";
import { useFetchWithJotai } from "frontend/hooks/use-fetch-with-jotai";
import AppLoader from "frontend/loader/app-loader";
import { useFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";
import { onUpgradeClickedOnMondayApp } from "utils/monday-app-utils";
import { iframeHasSessionToken } from "utils/url-utils";

export default function UpgradeTrialModal({
  user,
  onDismiss,
  source,
  renderType,
}: {
  user: User;
  onDismiss: () => void;
  source?: CheckoutSource;
  renderType: "modal" | "page";
}) {
  //atoms
  const { data: plansData, isLoading } = useFetchWithJotai<PlanData[]>({
    key: "plansData",
    fetchFn: getPlansData,
    atom: plansDataAtom,
  });
  const trialActive = isTrialActive(user.planInfo!);
  const [seatsCount, setSeatsCount] = useState<number>(user.planInfo!.seats_count!);

  const { userAccountCount, loadUserAccountCount } = useSeatsCount(user);
  const isPerSeatLicenseEnabled = useFeatureFlag("per-seat-license");

  const currentPlan = trialActive ? Plan.basic : stringToPlan(user.planInfo!.name!); //Treat trial as a basic plan. TODO: Should model trial as a seperate plan in db to avoid this hack.
  const currentInterval = user.planInfo?.interval;

  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "trial-choose-plan-modal-showed", renderType);
    (async () => {
      try {
        await triggerAction(consts.MAIL_TRIGGERS.UPGRADE_PLAN);
      } catch {
        // couldn't trigger
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      user && (await loadUserAccountCount());
    })();
  }, [isPerSeatLicenseEnabled]);

  useEffect(() => {
    setSeatsCount(Math.max(userAccountCount, seatsCount));
  }, [userAccountCount]);

  function noModalToRender() {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "no-plansData-value");
    return null;
  }

  function renderPaymentModal(plansData: PlanData[]) {
    const selectedPlanData = plansData.find((i) => i.name.toLowerCase() === "team"); //We assume all plans have the same trial days here, so we just take randomally from the teams plan. We should change it if this assumption changes
    const trialDays = selectedPlanData?.trialDays ?? consts.TRIAL_DAYS_DEFAULT_PERIOD;
    const priceByPlan = getPriceByPlanMapping(plansData);

    return (
      <PaymentModal
        user={user}
        currentPlan={currentPlan!}
        currentInterval={currentInterval!}
        priceByPlan={priceByPlan}
        trialDays={trialDays}
        seatsCount={seatsCount}
        onDismiss={onDismiss}
        source={source}
      />
    );
  }

  function renderPaymentPage(plansData: PlanData[]) {
    const selectedPlanData = plansData.find((i) => i.name.toLowerCase() === "team"); //We assume all plans have the same trial days here, so we just take randomally from the teams plan. We should change it if this assumption changes
    const trialDays = selectedPlanData?.trialDays ?? consts.TRIAL_DAYS_DEFAULT_PERIOD;
    const priceByPlan = getPriceByPlanMapping(plansData);

    return (
      <PaymentPage
        user={user}
        priceByPlan={priceByPlan}
        trialDays={trialDays}
        seatsCount={seatsCount}
        onDismiss={onDismiss}
        source={source}
      />
    );
  }

  function render() {
    if (iframeHasSessionToken()) {
      onUpgradeClickedOnMondayApp(source?.value || "");
      onDismiss();
      return null;
    } else if (isLoading) {
      return <AppLoader />;
    } else if (plansData) {
      return renderType === "page" ? renderPaymentPage(plansData) : renderPaymentModal(plansData);
    } else {
      return noModalToRender();
    }
  }

  return render();
}
