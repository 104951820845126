import { DOMPlacement } from "frontend/ui-components/floaters/position-utils";
import StyledButton, { ButtonSize } from "frontend/ui-components/styled-button";
import { CSSProperties } from "react";
import style from "./content-card-tip.module.css";
import { CloseIcon } from "frontend/icons/close";

const ARROW_SIZE = 15;

function arrowPosition(placement: DOMPlacement) {
  const { side = "bottom", shift = 0 } = placement;
  let top, left, right, bottom, backgroundColor;
  let transform;
  switch (side) {
    case "top": {
      bottom = 0;
      left = `max(min(calc( 50% - ${shift}px ), calc( 100% - ${ARROW_SIZE}px )), ${ARROW_SIZE}px)`;
      transform = "translate(-50%, 50%) rotate(-45deg)";
      backgroundColor = "#113357";
      break;
    }
    case "bottom": {
      top = 0;
      left = `min(max(calc( 50% - ${shift}px ), ${ARROW_SIZE}px), calc( 100% - ${ARROW_SIZE}px ))`;
      transform = "translate(-50%, -50%) rotate(135deg)";
      backgroundColor = "#fff";
      break;
    }
    case "left": {
      top = `min(max(calc( 50% - ${shift}px ), ${ARROW_SIZE}px), calc( 100% - ${ARROW_SIZE}px ))`;
      right = 0;
      transform = "translate(-50%, -50%) rotate(225deg)";
      if (shift < ARROW_SIZE / 2) {
        backgroundColor = "#113357";
      } else {
        backgroundColor = "#fff";
      }
      break;
    }
    case "right": {
      top = `min(max(calc( 50% - ${shift}px ), ${ARROW_SIZE}px), calc( 100% - ${ARROW_SIZE}px ))`;
      left = 0;
      transform = "translate(-50%, -50%) rotate(45deg)";
      if (shift < ARROW_SIZE / 2) {
        backgroundColor = "#113357";
      } else {
        backgroundColor = "#fff";
      }
      break;
    }
    default: {
      // We should never get here!
      // Will throw build error if all cases were not covered
      const _unhandled: never = side;
    }
  }
  return {
    divStyle: {
      position: "absolute",
      top,
      left,
      right,
      bottom,
      transform,
    } as CSSProperties,
    svgStyle: {
      width: ARROW_SIZE,
      height: ARROW_SIZE,
      viewBox: `0 0 ${ARROW_SIZE} ${ARROW_SIZE}`,
      fill: backgroundColor,
    },
  };
}

interface ContentCardTipProps {
  tip: {
    title: string;
    text: string;
    image: string;
    buttonTitle?: string;
  };
  onDismiss?: () => void;
  placement?: DOMPlacement;
  onActionClick?: () => void;
  showArrow?: boolean;
}

export default function ContentCardTip({
  tip,
  onDismiss,
  placement,
  onActionClick,
  showArrow = true,
}: ContentCardTipProps) {
  const { title, text, image, buttonTitle = "Got it" } = tip;

  let arrowSvgStyle, arrowDivStyle;
  if (placement) {
    const { divStyle, svgStyle } = arrowPosition(placement);
    arrowSvgStyle = svgStyle;
    arrowDivStyle = divStyle;
  }

  return (
    <div className={style.container}>
      {!!onDismiss && <CloseIcon onClick={onDismiss} className={style.closeIcon} />}
      {showArrow && placement && (
        <div className={style.arrow} style={arrowDivStyle}>
          <svg {...arrowSvgStyle}>
            <polygon points={`0,0 0,15 15,15`} />
          </svg>
        </div>
      )}
      <div style={{ position: "relative" }}>
        <div className={style.image} style={{ backgroundImage: `url(${image})` }} />
      </div>
      <div className={style.content}>
        <div className={style.title}>{title}</div>
        <div className={style.description}>{text}</div>
        <div className={style.buttonContainer}>
          <p />
          <StyledButton title={buttonTitle} size={ButtonSize.small} onClick={onActionClick} />
        </div>
      </div>
    </div>
  );
}
