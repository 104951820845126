import React, { useEffect, useRef, useState } from "react";
import { Permission, User } from "shared/datamodel/schemas/user";
import { State, Team, UserTeamPermission } from "shared/datamodel/schemas/team";
import ShareModalUserCell from "../share-user-model-cell";
import { Project, UserProjectPermission } from "shared/datamodel/schemas/project";
import style from "./manage-collaboration-modal-new.module.css";
import permissionStyle from "./permission-menu-new.module.css";
import { useOnClickOutside } from "usehooks-ts";
import classNames from "classnames";
import { Floater } from "frontend/ui-components/floaters/floater";
import useStateValue from "frontend/state/value";
import useAbility from "frontend/hooks/use-ability";
import { InstanceType, UserRole, getUserRole } from "shared/datamodel/schemas/ability";
import StyledButton from "frontend/ui-components/styled-button";
import useFeatureValue from "frontend/hooks/use-features";
import consts from "shared/consts";
import { BoardWithMembers } from "shared/datamodel/schemas";
import { SelectedIcon } from "frontend/icons/selected";
import tracking from "frontend/tracking";
import { useInstanceMembers } from "frontend/hooks/use-instance-members";

export function MemberRow({
  teammate,
  members = [],
  setMembers,
  index,
  instance,
  instanceType,
  isOwner = false,
  shouldShowPermissionMenu,
  setChangingPermissionMessage,
  onRestrictedUserInvite,
}: {
  teammate: User;
  members?: User[];
  setMembers?: (members: User[]) => void;
  index: number;
  instance: Project | Team | BoardWithMembers;
  instanceType: InstanceType;
  isOwner?: boolean;
  shouldShowPermissionMenu?: boolean;
  setChangingPermissionMessage?: (message: string) => void;
  onRestrictedUserInvite?: (user: User) => void;
}) {
  const [{ user }] = useStateValue();
  const { canMemberEditInstance } = useAbility();

  const permission = canMemberEditInstance(teammate, instance, instanceType) ? "Editor" : "Viewer";

  const [userPermission, setUserPermission] = useState(permission);
  const [showPermissionMenu, setShowPermissionMenu] = useState(shouldShowPermissionMenu ?? false);
  const [didInvite, setDidInvite] = useState(false);

  const { updateMembers: handleEditMembers } = useInstanceMembers(
    instanceType === InstanceType.Board ? instance.documentId : instance.id.toString(),
    instanceType
  );

  const applyRestrictedLimitation = useFeatureValue(consts.FEATURE_NAMES.APPLY_VIEWER_RESTRICTED) === "true";
  const teammateRole = user && getUserRole(teammate, applyRestrictedLimitation);

  const permissionRef = useRef(null);
  const permissionMenuRef = useRef(null);
  const inviteColumnHidden = true;

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setUserPermission(permission);
  }, [teammate.isSubscribedToCanvas, permission]);

  useOnClickOutside(permissionMenuRef, () => {
    setShowPermissionMenu(false);
  });

  function getInstanceName(): string {
    switch (instanceType) {
      case InstanceType.Board: {
        return (instance as BoardWithMembers).name;
      }
      case InstanceType.Project: {
        return (instance as Project).name;
      }
      case InstanceType.Team: {
        return (instance as Team).name;
      }
      default: {
        return "";
      }
    }
  }

  const handleRemove = async (entityName: string) => {
    switch (instanceType) {
      case InstanceType.Board: {
        handleEditMembers({
          documentId: (instance as BoardWithMembers).documentId,
          members: [{ id: Number(teammate.id), permission: Permission.viewer, deleted: true }],
        });
        break;
      }
      case InstanceType.Project: {
        const teammateNewPermission: UserProjectPermission = {
          id: teammate.id,
          permission: teammate.permission ?? Permission.editor,
          state: State.deleted,
        };

        handleEditMembers({
          ...(instance as Project),
          userProjectPermissions: [teammateNewPermission],
        });

        tracking.trackAnalyticsEvent("remove_project_members", {
          category: consts.TRACKING_CATEGORY.PROJECT_ACTION,
          projectName: instance.name,
        });
        break;
      }
      case InstanceType.Team: {
        const teammateNewPermission: UserTeamPermission = {
          id: teammate.id,
          permission: Permission.viewer,
          state: State.deleted,
        };
        handleEditMembers({
          ...(instance as Team),
          userTeamPermissions: [teammateNewPermission],
        });
        tracking.trackAnalyticsEvent("remove_team_members", {
          category: consts.TRACKING_CATEGORY.TEAM_ACTION,
          teamName: instance.name,
        });
        break;
      }
    }
    setChangingPermissionMessage?.(`"${teammate.name}" has been removed from "${entityName}"`);
  };

  const changeInstanceMemberPermission = (permissionToDB: Permission) => {
    switch (instanceType) {
      case InstanceType.Board: {
        handleEditMembers({
          documentId: (instance as BoardWithMembers).documentId,
          members: [{ id: Number(teammate.id), permission: permissionToDB, deleted: false }],
        });
        break;
      }
      case InstanceType.Project: {
        const teammateNewPermission: UserProjectPermission = {
          id: teammate.id,
          permission: permissionToDB ?? Permission.editor,
          state: State.active,
        };

        handleEditMembers({
          ...(instance as Project),
          userProjectPermissions: [teammateNewPermission],
        });

        tracking.trackAnalyticsEvent("edit_project_members", {
          category: consts.TRACKING_CATEGORY.PROJECT_ACTION,
          projectName: instance.name,
        });
        break;
      }
      case InstanceType.Team: {
        const teammateNewPermission: UserTeamPermission = {
          id: teammate.id,
          permission: permissionToDB ?? teammate.permission ?? Permission.editor,
          state: State.active,
        };
        handleEditMembers({
          ...(instance as Team),
          userTeamPermissions: [teammateNewPermission],
        });
        tracking.trackAnalyticsEvent("edit_team_members", {
          category: consts.TRACKING_CATEGORY.TEAM_ACTION,
          teamName: instance.name,
        });
        break;
      }
    }
  };

  const handleDropdownChange = (value: string, setValue: (value: string) => void) => {
    const entityName = getInstanceName();
    if (value === "Remove") {
      handleRemove(entityName);
    } else {
      setValue(value);
      const permissionToDB = value === "Editor" ? Permission.editor : Permission.viewer;
      changeInstanceMemberPermission(permissionToDB);
      setChangingPermissionMessage?.(
        `"${teammate.name}" permission has been changed to ${value.toLowerCase()} on "${entityName}"`
      );
    }
  };

  const renderMembersPermissionDropDown = (value: string, setValue: (value: string) => void, teammate: User) => {
    const { id: userId, email: userEmail } = teammate;
    const shouldRenderRemove = instance.isOwner;

    return (
      <>
        <div
          data-testid={`${userEmail}-permissionsdropdown`}
          className={permissionStyle.permissionDropDown}
          ref={permissionRef}
          onClick={() => setShowPermissionMenu(true)}
        >
          <span>{value}</span>
          <span>
            <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.11442 1L4.55713 4.55729" stroke="#11355E" strokeLinecap="square" />
              <path d="M1 1L4.55729 4.55729" stroke="#11355E" strokeLinecap="square" />
            </svg>
          </span>
        </div>
        {showPermissionMenu && (
          <Floater
            relativeTo={permissionRef}
            side={"bottom"}
            extraStyle={{
              zIndex: "1000",
              filter: "drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.20))",
              marginLeft: "-10px",
              marginTop: "-12px",
            }}
            ref={permissionMenuRef}
          >
            <div className={style.arrow} />
            <div className={permissionStyle.permissionMenu}>
              <div
                data-testid="Viewer"
                className={classNames(permissionStyle.item, {
                  [permissionStyle.selectedPermission]: value === "Viewer",
                })}
                onClick={() => {
                  handleDropdownChange("Viewer", setValue);
                  setShowPermissionMenu(false);
                }}
              >
                <span className={classNames(permissionStyle.name, { [permissionStyle.bold]: value === "Viewer" })}>
                  Viewer
                </span>
                {value === "Viewer" && <SelectedIcon />}
              </div>
              <div
                data-testid="Editor"
                className={classNames(permissionStyle.item, {
                  [permissionStyle.selectedPermission]: value === "Editor",
                })}
                onClick={() => {
                  handleDropdownChange("Editor", setValue);
                  setShowPermissionMenu(false);
                }}
              >
                <span className={classNames(permissionStyle.name, { [permissionStyle.bold]: value === "Editor" })}>
                  Editor
                </span>
                {value === "Editor" && <SelectedIcon />}
              </div>
              {shouldRenderRemove && (
                <div
                  data-testid="Remove"
                  className={permissionStyle.item}
                  onClick={() => {
                    handleDropdownChange("Remove", setValue);
                    setMembers && setMembers(members.filter((m) => m.id !== userId));
                    setShowPermissionMenu(false);
                  }}
                >
                  <span className={permissionStyle.name}> Remove </span>
                </div>
              )}
            </div>
          </Floater>
        )}
      </>
    );
  };

  const renderMembersPermission = (value: string) => {
    return (
      <div className={style.permission}>
        <span>{value}</span>
      </div>
    );
  };

  function renderRestrictedMemberInviteButton() {
    if (inviteColumnHidden) {
      return null;
    }
    return (
      <div className={style.invite}>
        {teammateRole === UserRole.Restricted && onRestrictedUserInvite && (
          <StyledButton
            title={didInvite ? "Invited" : "Invite"}
            customStyle={{
              width: 57,
              height: 24,
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: 12,
              fontStyle: "normal",
              fontWeight: 400,
              letterSpacing: "0.219",
            }}
            enabled={!didInvite}
            onClick={() => {
              onRestrictedUserInvite(teammate);
              setDidInvite(true);
            }}
          />
        )}
      </div>
    );
  }

  function renderPermissionActions() {
    const permissionSelectionDisabled = !isOwner || (!teammate.isSubscribedToCanvas && applyRestrictedLimitation);
    return (
      <div
        className={classNames(style.userPermission, {
          [style.flexStart]: !permissionSelectionDisabled || !inviteColumnHidden,
          [style.narrowDropdown]: inviteColumnHidden,
        })}
      >
        {permissionSelectionDisabled
          ? renderMembersPermission(userPermission)
          : renderMembersPermissionDropDown(userPermission, setUserPermission, teammate)}
      </div>
    );
  }

  return (
    <div
      data-testid={teammate.email}
      key={index}
      className={classNames(style.userLine, { [style.checkListLine]: isOwner })}
      ref={ref}
    >
      <div className={classNames(style.listUser, { [style.checkListUser]: isOwner })}>
        <ShareModalUserCell key={index} user={teammate} />
      </div>
      {renderPermissionActions()}
      {renderRestrictedMemberInviteButton()}
    </div>
  );
}
