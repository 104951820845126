import { User } from "shared/datamodel/schemas/user";
import style from "./invite-user-cell.module.css";
import classNames from "classnames";
import { useEffect, useRef } from "react";

interface InviteUserCellProps {
  user: User;
  onClick: (user: User) => void;
  rounded?: boolean;
  isSelected?: boolean;
}

export default function InviteUserCell({ user, onClick, rounded, isSelected }: InviteUserCellProps) {
  const buttonRef = useRef<HTMLDivElement>(null);

  function handleClick(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    onClick(user);
  }

  useEffect(() => {
    if (isSelected) {
      buttonRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [isSelected]);

  return (
    <div
      ref={buttonRef}
      role="button"
      className={classNames(style.container, {
        [style.rounded]: rounded,
        [style.selected]: isSelected,
      })}
      onClick={handleClick}
      tabIndex={0}
    >
      <img className={style.avatar} src={user.thumbnail} alt={user.name} />
      <div className={style.innerContainer}>
        <span className={style.name}>{user.name}</span>
        <span className={style.verticalDivider}>|</span>
        <span className={style.email}>{user.email}</span>
      </div>
    </div>
  );
}
