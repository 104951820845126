import { useAtom, useAtomValue } from "jotai";
import { useEffect, useRef } from "react";
import { TipName } from "shared/datamodel/schemas";
import { syncServiceAtom, userAtom, userTipsAtom } from "state-atoms";
import useAbility from "./use-ability";
import { useBoardValue } from "frontend/state/board-state";
import { isUserPaying } from "utils/users-utils";
import { useFeatureFlag, useFeatureFlagVariant } from "./use-feature-flag/use-feature-flag";
import { markTipSeen } from "frontend/api";
import tracking from "frontend/tracking";
import consts from "shared/consts";

function markTipSeenSafe(tipName: TipName) {
  try {
    markTipSeen(tipName);
  } catch {
    //
  }
}

export default function useShouldShowInviteToBoardPopup(onShowInviteCallback: () => void) {
  const isFirstAccess = useRef(sessionStorage.getItem(consts.SESSION_STORAGE_KEYS.FIRST_ACCESS));
  const user = useAtomValue(userAtom);
  const [userTips, setUserTips] = useAtom(userTipsAtom);
  const syncService = useAtomValue(syncServiceAtom);
  const { canShareBoard } = useAbility();
  const [{ board, isStageReady }] = useBoardValue();
  const userCanShareBoard = user && board && canShareBoard(user, board);
  const isPayingUser = isUserPaying(user?.planInfo ?? null);
  const isInviteTeammatesToBoardEnabled = useFeatureFlag("ab-test-invite-teammates-to-board-popup");
  const inviteTeammatesToBoardPopupVariant = useFeatureFlagVariant("ab-test-invite-teammates-to-board-popup");

  const userHasCompletedWalkthrough =
    userTips.has(TipName.canvasWalkthrough) && !userTips.has(TipName.inviteTeammatesFromActiveCanvas);

  const isEligibleUser = !!(user && userHasCompletedWalkthrough && isPayingUser && userCanShareBoard);

  const isLoaded = !!(syncService?.getReplicache() && isStageReady);

  const showPopup = !!(isLoaded && isInviteTeammatesToBoardEnabled && isEligibleUser && !isFirstAccess.current);

  useEffect(() => {
    if (showPopup) {
      markTipSeenSafe(TipName.inviteTeammatesFromActiveCanvas);
      setUserTips((tips) => new Set([...tips, TipName.inviteTeammatesFromActiveCanvas]));
      tracking.trackAnalyticsEvent("invite_popup_from_active_canvas_shown", {
        variant: inviteTeammatesToBoardPopupVariant,
      });
      onShowInviteCallback();
    }
  }, [showPopup, inviteTeammatesToBoardPopupVariant]);

  return showPopup;
}
