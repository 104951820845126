import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import WalkthroughTipPresenter from "frontend/walkthrough-tips/walkthrough-tip-presenter";
import { CSSProperties, useCallback, useRef, useState } from "react";
import { TipName } from "shared/datamodel/schemas";
import BigModalContainer from "./big-modal-container";
import InviteView from "./onboarding/invite-view";
import useShouldShowInviteToBoardPopup from "frontend/hooks/use-show-invite-to-board-popup";
import { useFeatureFlagVariant } from "frontend/hooks/use-feature-flag/use-feature-flag";
import CanvasContentCard from "frontend/ui-components/canvas-content-card/canvas-content-card";

export default function InviteToBoardButton({
  title = "Share",
  style,
  customStyle,
  onInviteClick,
}: {
  title?: string;
  style?: ButtonStyle;
  customStyle?: CSSProperties;
  onInviteClick?: (title?: string) => void;
}) {
  const ref = useRef(null);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const inviteTeammatesToBoardPopupVariant = useFeatureFlagVariant("ab-test-invite-teammates-to-board-popup");

  const isTestVariant = inviteTeammatesToBoardPopupVariant === "test";

  const inviteClickHandler = useCallback(() => {
    onInviteClick && onInviteClick(title);
  }, [onInviteClick, title]);

  const onShowInviteCallback = useCallback(() => {
    if (isTestVariant) {
      setShowInviteModal(true);
    } else {
      inviteClickHandler();
    }
  }, [isTestVariant, inviteClickHandler]);

  const showInviteToBoardPopup = useShouldShowInviteToBoardPopup(onShowInviteCallback);

  return (
    <>
      <StyledButton
        id="invite-to-board-button"
        title={title}
        onClick={inviteClickHandler}
        style={style}
        customStyle={customStyle}
        ref={ref}
      />
      <WalkthroughTipPresenter tipName={TipName.walkthroughInviteMembers} attachToRef={ref} side="bottom" />
      {!showInviteToBoardPopup && <CanvasContentCard attachToRef={ref} onClick={inviteClickHandler} />}
      {showInviteModal && (
        <BigModalContainer>
          <InviteView onCompleted={() => setShowInviteModal(false)} formTitle="Invite your team to collaborate" />
        </BigModalContainer>
      )}
    </>
  );
}
