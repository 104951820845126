import { useEffect, useRef, useState } from "react";
import style from "../collaboration/manage-collaboration-modal.module.css";
import CollaborationHeader from "../collaboration/collaboration-header";
import { Team } from "shared/datamodel/schemas/team";
import { Project } from "shared/datamodel/schemas/project";
import { MemberRow } from "./member-row";
import useOutsideRef from "frontend/utils/click-outside-handler";
import useStateValue from "frontend/state/value";
import Skeleton from "react-loading-skeleton";
import { InstanceType } from "shared/datamodel/schemas/ability";
import SearchEmptyState from "../collaboration/empty-state/search-empty-state";
import SendInviteToast from "frontend/ui-components/toast/send-invite-toast";
import useFeatureValue from "frontend/hooks/use-features";
import consts from "shared/consts";
import { useTeamsCache } from "frontend/hooks/caching/use-teams-cache";
import { useProjectsCache } from "frontend/hooks/caching/use-projects-cache";
import { useInstanceMembers } from "frontend/hooks/use-instance-members";

export default function ManageCollaborationMembers({
  onDismiss,
  instanceId,
  type,
  onEditSeats,
}: {
  onDismiss: () => void;
  instanceId: number;
  type: InstanceType;
  onEditSeats?: () => void;
}) {
  const [{ user }] = useStateValue();
  const { getTeams } = useTeamsCache();
  const { getProjects } = useProjectsCache();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [inviteeName, setInviteeName] = useState<string | null>(null);
  const [instance, setInstance] = useState<Team | Project | null>(null);
  const collaborationModalRef = useRef(null);
  const { members, isLoading: isMembersLoading } = useInstanceMembers(instanceId.toString(), type);

  const isFreePlan = !user || !user.planInfo || user.planInfo.is_free;
  const applyRestrictedLimitation = useFeatureValue(consts.FEATURE_NAMES.APPLY_VIEWER_RESTRICTED) === "true";
  const isTouchAccount = user?.planInfo?.source === "touch";
  const canManageSeats = !isFreePlan && !isTouchAccount && user.isAdmin && user.isSubscribedToCanvas;

  useOutsideRef(collaborationModalRef, () => {
    onDismiss();
  });

  useEffect(() => {
    async function loadUsers() {
      if (type === InstanceType.Team) {
        const teams = await getTeams([instanceId.toString()]);
        if (teams.length === 0) return;
        setInstance(teams[0]);
      } else {
        const projects = await getProjects([instanceId.toString()]);
        if (projects.length === 0) return;
        setInstance(projects[0]);
      }
    }
    loadUsers();
  }, [instanceId, type]);

  //Hide the ask to edit message after some interval
  useEffect(() => {
    let timeOut: NodeJS.Timeout;
    if (inviteeName) {
      timeOut = setTimeout(() => {
        setInviteeName(null);
      }, 6000);
    }
    return () => timeOut && clearTimeout(timeOut);
  }, [inviteeName]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredMembers = members.filter((member) => member.name.toLowerCase().includes(searchQuery.toLowerCase()));

  const renderMembers = () => {
    if (members.length > 0 && filteredMembers.length === 0 && !isMembersLoading && !instance) {
      return <SearchEmptyState searchTerm={searchQuery} subtitle="in team members" />;
    }
    const sortedMembersBySubscription = !applyRestrictedLimitation
      ? filteredMembers
      : [...filteredMembers].sort((a, b) => {
          if (a.isSubscribedToCanvas && !b.isSubscribedToCanvas) return 1;
          if (!a.isSubscribedToCanvas && b.isSubscribedToCanvas) return -1;
          return 0;
        });

    return sortedMembersBySubscription?.map(
      (teammate, index) =>
        instance && (
          <MemberRow
            key={teammate.id}
            teammate={teammate}
            members={members}
            index={index}
            isOwner={instance?.isOwner}
            instance={instance}
            instanceType={type}
          />
        )
    );
  };

  return (
    <div className={style.container} ref={collaborationModalRef}>
      <div style={{ padding: "25px 25px 20px 25px" }}>
        <CollaborationHeader
          title={instance?.isOwner ? `Manage  "${instance.name}"` : `"${instance?.name}" members`}
          onDismiss={() => {
            onDismiss();
          }}
        />
        <div className={style.search}>
          <div className={style.subtitleContainer}>
            <span className={style.subtitle}>Search a member</span>
            {canManageSeats && onEditSeats && (
              <span className={style.manageSeats} onClick={onEditSeats}>
                Manage seats
              </span>
            )}
          </div>
          <div className={style.searchContainer}>
            <input
              placeholder="Search members..."
              value={searchQuery}
              onChange={handleSearch}
              className={style.searchInput}
              autoFocus={true}
            />
          </div>
        </div>
      </div>
      <div className={style.divider}></div>
      <div className={style.collaborators}>
        {renderMembers()}
        {isMembersLoading ? (
          <Skeleton count={4} className={style.userLineSkeleton} containerClassName={style.collaborators} />
        ) : null}
      </div>
      {inviteeName && <SendInviteToast inviteeName={inviteeName} />}
    </div>
  );
}
