import { RefObject, useMemo, useRef } from "react";
import classNames from "classnames";
import type { BubblesFieldProps } from "../types";
import { Tag } from "./tags/tag";
import { EditableTag } from "./tags/editable-tag";
import style from "./form-bubbles-field-v2.module.css";
import { useClipboardPasteListener, useFormBubblesFieldState, useHandleClickOutside } from "./hooks";
import InviteUsersSelect from "./invite-users-select/invite-users-select";
import { useInviteUsersSelect, useInviteUsersSelectState } from "./invite-users-select/hooks";

export default function FormBubblesFieldV2(props: BubblesFieldProps) {
  const { value: values, placeholder, highlightInvalid, autoFocus = false, customStyle, rounded, onChange } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const measureSpanRef = useRef<HTMLSpanElement>(null);
  const usersDropdownRef = useRef<HTMLDivElement>(null);

  const existingValues = useMemo(() => new Set(values.map((v) => v.value.toLowerCase())), [values]);

  const {
    inputWidth,
    focusedTagIndex,
    editingState,
    isInputFieldFocused,
    handleFocus,
    handleBlur,
    handleDelete,
    handleEdit,
    handleEditComplete,
    onHandleCancelEditing,
    handleInput,
  } = useFormBubblesFieldState(props, inputRef, measureSpanRef);

  const {
    showDropdown,
    setShowDropdown,
    inputValue,
    setInputValue,
    handleUserClick,
    handleCustomBlur,
    handleCustomInput,
    handleCustomKeyDown,
    handleCustomFocus,
    selectedIndex,
  } = useInviteUsersSelect(inputRef, usersDropdownRef, existingValues, onChange, handleBlur, handleFocus, handleInput);

  const { users, showInviteUsersSelect } = useInviteUsersSelectState(inputValue, showDropdown, isInputFieldFocused);

  useClipboardPasteListener(inputRef, onChange, setShowDropdown, existingValues);
  useHandleClickOutside(containerRef, inputRef, usersDropdownRef, onChange);

  const handleContainerClick = (e: React.MouseEvent, inputRef: RefObject<HTMLInputElement>) => {
    setShowDropdown(true);
    if (inputRef.current?.value) {
      setInputValue(inputRef.current.value);
    }

    if (e.target !== inputRef.current && inputRef?.current) {
      inputRef.current.focus();
      const length = inputRef.current.value.length;
      inputRef.current.setSelectionRange(length, length);
    }
  };

  return (
    <div>
      <div
        className={classNames(style.container, {
          [style.rounded]: rounded && !showInviteUsersSelect,
          [style.containerDropdownOpen]: showInviteUsersSelect && rounded,
        })}
        onClick={(e) => handleContainerClick(e, inputRef)}
        ref={containerRef}
        style={customStyle}
      >
        {values.map((bubbleFieldValue, index) =>
          editingState?.index === index ? (
            <EditableTag
              key={bubbleFieldValue.id}
              initialValue={bubbleFieldValue.value}
              onComplete={(newValue, navigationKey) => {
                setShowDropdown(false);
                handleEditComplete(bubbleFieldValue.id, bubbleFieldValue.value, newValue, navigationKey);
              }}
              onCancel={onHandleCancelEditing}
              mainInputRef={inputRef}
              cursorAtStart={editingState.cursorAtStart}
              rounded={rounded}
            />
          ) : (
            <Tag
              key={bubbleFieldValue.id}
              bubbleFieldValue={bubbleFieldValue}
              isFocused={focusedTagIndex === index}
              highlightInvalid={highlightInvalid}
              rounded={rounded}
              onDelete={(value, id) => {
                setShowDropdown(false);
                handleDelete(value, id);
              }}
              onEdit={(value) => handleEdit(value, index)}
            />
          )
        )}
        <input
          autoFocus={autoFocus}
          autoComplete="off"
          ref={inputRef}
          placeholder={values.length === 0 ? placeholder : ""}
          className={style.input}
          onInput={handleCustomInput}
          onFocus={handleCustomFocus}
          onBlur={handleCustomBlur}
          onKeyDown={editingState ? undefined : (e) => handleCustomKeyDown(e, users)}
          style={{ width: `${inputWidth}px` }}
        />
        <span ref={measureSpanRef} className={style.hiddenMeasureSpan} />
      </div>
      {showInviteUsersSelect && (
        <InviteUsersSelect
          users={users}
          usersDropdownRef={usersDropdownRef}
          inputValue={inputValue}
          rounded={rounded}
          handleUserClick={handleUserClick}
          selectedIndex={selectedIndex}
        />
      )}
    </div>
  );
}
