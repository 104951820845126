import { getTeamsWithProjects } from "frontend/api";
import { useAtom } from "jotai";
import { Team, TeamWithProjects } from "shared/datamodel/schemas/team";
import { Project } from "shared/datamodel/schemas/project";
import { teamsWithProjectsAtom } from "state-atoms";
import { pick } from "rambda";

export function useTeamsWithProjects() {
  const [teamsWithProjects, setTeamsWithProjects] = useAtom(teamsWithProjectsAtom);

  const addTeam = (team: Pick<Team, "id" | "name" | "permission" | "isOwner">) => {
    setTeamsWithProjects((previous) => [...previous, { ...team, projects: [] }]);
  };

  const updateTeam = (teamId: number, updates: Partial<Team>) => {
    updates = pick(["name", "permission", "isOwner"], updates);
    setTeamsWithProjects((previous) =>
      previous.map((team) => (team.id === teamId ? { ...team, ...updates, projects: team.projects ?? [] } : team))
    );
  };

  const deleteTeam = (teamId: number) => {
    setTeamsWithProjects((previous) => previous.filter((team) => team.id !== teamId));
  };

  const addProject = (teamId: number, project: Pick<Project, "id" | "name" | "permission" | "isOwner">) => {
    setTeamsWithProjects((previous) =>
      previous.map((team) => (team.id === teamId ? { ...team, projects: [...team.projects, project] } : team))
    );
  };

  const updateProject = (
    teamId: number,
    projectId: number,
    updates: Partial<Pick<Project, "name" | "permission" | "isOwner">>
  ) => {
    setTeamsWithProjects((previous) =>
      previous.map((team) =>
        team.id === teamId
          ? {
              ...team,
              projects: team.projects?.map((project) =>
                project.id === projectId ? { ...project, ...updates } : project
              ),
            }
          : team
      )
    );
  };

  const deleteProject = (teamId: number, projectId: number) => {
    setTeamsWithProjects((previous) =>
      previous.map((team) =>
        team.id === teamId
          ? {
              ...team,
              projects: team.projects?.filter((project) => project.id !== projectId),
            }
          : team
      )
    );
  };

  const fetchTeamsWithProjects = async (): Promise<TeamWithProjects[]> => {
    const teamsWithProjects = await getTeamsWithProjects();
    setTeamsWithProjects(teamsWithProjects);
    return teamsWithProjects;
  };

  return {
    teamsWithProjects,
    fetchTeamsWithProjects,
    addTeam,
    updateTeam,
    deleteTeam,
    addProject,
    updateProject,
    deleteProject,
  };
}
