import React from "react";

export const BillingIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
      <line x1="1" y1="4.4" x2="14" y2="4.4" stroke="#113357" strokeWidth="1.2" />
      <line
        x1="6.21523"
        y1="8.09141"
        x2="8.09216"
        y2="8.09141"
        stroke="#113357"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <line
        x1="3.90762"
        y1="8.09141"
        x2="4.24608"
        y2="8.09141"
        stroke="#113357"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <rect x="1" y="1" width="13.0769" height="10" rx="2" stroke="#113357" strokeWidth="1.2" />
    </svg>
  );
};
