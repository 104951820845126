import { useTeamsCache } from "./caching/use-teams-cache";
import { useProjectsCache } from "./caching/use-projects-cache";
import { useBoardMembersCache } from "./caching/use-boards-members-cache";
import { useUsersCache } from "./caching/use-users-cache";
import { useState, useEffect } from "react";
import { InstanceType } from "shared/datamodel/schemas/ability";
import { User } from "shared/datamodel/schemas";
import { Team } from "shared/datamodel/schemas/team";
import { Project } from "shared/datamodel/schemas/project";
import { BoardMembers } from "shared/datamodel/schemas/board";

export function useInstanceMembers(instanceId: string | null, instanceType: InstanceType) {
  const { getTeams, updateTeam } = useTeamsCache();
  const { getProjects, updateProject } = useProjectsCache();
  const { getBoardMembers, updateBoardMembers } = useBoardMembersCache();
  const { getUsers } = useUsersCache();
  const [members, setMembers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const setTeamMembers = async (team: Team) => {
    if (!team?.userTeamPermissions) {
      return;
    }
    const users = await getUsers(team.userTeamPermissions.map((m) => m.id));
    const members = users
      .map((user) => ({
        ...user,
        permission: team.userTeamPermissions.find((m) => m.id === user.id)?.permission,
      }))
      .filter((user) => user !== null) as User[];
    setMembers(members);
  };

  const setProjectMembers = async (project: Project) => {
    if (!project?.userProjectPermissions) {
      return;
    }
    const users = await getUsers(project.userProjectPermissions.map((m) => m.id));
    const members = users
      .map((user) => ({
        ...user,
        permission: project.userProjectPermissions.find((m) => m.id === user.id)?.permission,
      }))
      .filter((user) => user !== null) as User[];
    setMembers(members);
  };

  const setBoardMembers = async (boardMembers: BoardMembers) => {
    if (!boardMembers?.members) {
      setMembers([]);
      return;
    }

    const users = await getUsers(boardMembers.members.map((m) => m.id.toString()));
    const members = users
      .map((user) => {
        return {
          ...user,
          permission: boardMembers.members.find((m) => m.id.toString() === user.id)?.permission,
        };
      })
      .filter((user) => user !== null) as User[];
    setMembers(members);
  };

  useEffect(() => {
    async function fetchMembers() {
      if (!instanceId) {
        setMembers([]);
        return;
      }
      setIsLoading(true);
      switch (instanceType) {
        case InstanceType.Team: {
          const [team] = await getTeams([instanceId]);
          await setTeamMembers(team);
          break;
        }

        case InstanceType.Project: {
          const [project] = await getProjects([instanceId]);
          await setProjectMembers(project);
          break;
        }
        case InstanceType.Board: {
          const [boardMembers] = await getBoardMembers([instanceId]);
          await setBoardMembers(boardMembers);
          break;
        }
        default: {
          setMembers([]);
        }
      }
      setIsLoading(false);
    }

    fetchMembers();
  }, [instanceId, instanceType, getTeams, getProjects, getBoardMembers, getUsers]);

  const updateMembers = async (instance: Team | Project | BoardMembers) => {
    setIsLoading(true);
    switch (instanceType) {
      case InstanceType.Team: {
        await setTeamMembers(instance as Team);
        await updateTeam(instance as Team);
        break;
      }
      case InstanceType.Project: {
        await setProjectMembers(instance as Project);
        await updateProject(instance as Project);
        break;
      }
      case InstanceType.Board: {
        await setBoardMembers(instance as BoardMembers);
        await updateBoardMembers(instance as BoardMembers);
        break;
      }
    }
    setIsLoading(false);
  };

  return {
    members,
    isLoading,
    updateMembers,
  };
}
