import { MY_BOARDS_SECTION_ID, RECENT_BOARDS_SECTION_ID, SHARED_WITH_ME_SECTION_ID } from "state-atoms/boards-atom";

export enum BoardSectionType {
  PROJECT = "PROJECT",
  TEAM = "TEAM",
  MY_BOARDS = "MY_BOARDS",
  RECENT = "RECENT",
  SHARED_WITH_ME = "SHARED_WITH_ME",
  TEMPLATES = "TEMPLATES",
}

export interface BoardSection {
  id: number;
  type: BoardSectionType;
  parentId?: number;
}

export const getSectionId = (type: BoardSectionType, id: number): string => {
  switch (type) {
    case BoardSectionType.MY_BOARDS: {
      return MY_BOARDS_SECTION_ID;
    }
    case BoardSectionType.RECENT: {
      return RECENT_BOARDS_SECTION_ID;
    }
    case BoardSectionType.SHARED_WITH_ME: {
      return SHARED_WITH_ME_SECTION_ID;
    }
    case BoardSectionType.TEAM: {
      return `team-${id}`;
    }
    case BoardSectionType.PROJECT: {
      return `project-${id}`;
    }
    default: {
      return id.toString();
    }
  }
};
