const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const EMAIL_REGEX_GLOBAL =
  /(?!\.)[a-zA-Z0-9](?:[a-zA-Z0-9._%+\-]*[a-zA-Z0-9])?(?<!\.)@(?!\.)[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)*\.[a-zA-Z]{2,}/g;

export function isEmail(email: string): boolean {
  const match = email.match(EMAIL_REGEX);
  return !!match && match.length > 0;
}

export function getEmailsFromText(text: string): string[] {
  return [...text.matchAll(EMAIL_REGEX_GLOBAL)].map((match) => match[0]);
}
