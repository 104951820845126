import { createBoard } from "frontend/services/boardsService";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import { BlankCanvasId, BoardState, MondayUser } from "shared/datamodel/schemas";
import { getPathPrefix } from "./getPathPrefix";

const NOT_WORK_DOMAINS = new Set([
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "hotmail.co.uk",
  "hotmail.fr",
  "msn.com",
  "yahoo.fr",
  "wanadoo.fr",
  "orange.fr",
  "comcast.fr",
  "live.com",
]);

export function capitalizeAccountName(value: string): string {
  return value
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export async function createTemplateBoard(templateId: string) {
  try {
    const boardState = templateId === BlankCanvasId ? BoardState.default : BoardState.templateNotSetUp;
    if (boardState !== BoardState.default) {
      const name = "New Canvas";
      const board = await createBoard(name, boardState, templateId, null, null);
      return board;
    }
    return null;
  } catch {
    console.error("couldn't create template board");
  }
}

export function fieldOnFocus(id: string) {
  tracking.trackEvent(consts.TRACKING_CATEGORY.SIGNUP, "signup_field_focused", id);
}

// failed google login
export function onGoogleLoginField(error: unknown) {
  tracking.trackEvent(consts.TRACKING_CATEGORY.SIGNUP, "google_login_failed");
  console.error("google login failed", error);
}

export function isWorkEmail(email: string) {
  const domain = email.slice(Math.max(0, email.indexOf("@") + 1));

  return !NOT_WORK_DOMAINS.has(domain);
}

export function autoFillWorkEmail(email: string) {
  if (isWorkEmail(email)) {
    //extractEmailDomain
    const atIndex = email.indexOf("@");
    const dotIndex = email.lastIndexOf(".");
    const accountName = email.slice(atIndex + 1, dotIndex);
    return capitalizeAccountName(accountName);
  }
  return null;
}

export function onSignupCompleted(user: MondayUser) {
  tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.HARD_SIGNUP);
  tracking.trackEvent(consts.TRACKING_CATEGORY.ACQUISITION, "new_hard_signup", user.account_id, user.id);
}

const SIGN_UP_URL = getPathPrefix("/auth/signup");

export function openSignupPage(source: string) {
  tracking.trackAnalyticsEvent("guest_signup_clicked", { source });
  window.open(SIGN_UP_URL, "_blank");
}
