import style from "./delete-template-modal.module.css";
import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import { deleteCanvasTemplate } from "frontend/api";
import useCanvasTemplates from "frontend/hooks/use-canvas-templates";
import { useEffect } from "react";
import tracking from "frontend/tracking";
import consts from "shared/consts";

export default function DeleteTemplateModal({ templateId, onDismiss }: { templateId: string; onDismiss: () => void }) {
  const { deleteTemplate } = useCanvasTemplates();

  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.TEMPLATES, "delete-template-shown", templateId);
  }, []);

  function onDeleteClick() {
    templateId && deleteCanvasTemplate(templateId);
    deleteTemplate(templateId);
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.TEMPLATES, // category
      "delete_template", // action
      templateId
    );
    onDismiss();
  }

  function renderCloseIcon() {
    return (
      <svg
        className={style.closeIcon}
        onClick={(e: any) => {
          e.stopPropagation();
          onDismiss();
        }}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="close-template-modal"
      >
        <path
          d="M6.36407 6.36396L19.092 19.0919M19.092 6.36396L6.36407 19.0919"
          stroke="currentColor"
          strokeWidth="1.74"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <>
      <div className={style.deleteContainer}>
        {renderCloseIcon()}
        <p className={style.title}>Delete template?</p>
        <p>Are you sure you want to delete your template?</p>
        <div className={style.buttonsContainer}>
          <StyledButton
            title="Yes, delete"
            onClick={(e: any) => {
              e.stopPropagation();
              onDeleteClick();
            }}
            customStyle={{ width: 210, height: 40 }}
          />
          <StyledButton
            title="Oops, no"
            onClick={(e: any) => {
              e.stopPropagation();
              onDismiss();
            }}
            style={ButtonStyle.outline}
            customStyle={{ width: 210, height: 40 }}
          />
        </div>
      </div>
    </>
  );
}
