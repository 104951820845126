import { useAtom } from "jotai";
import { useCallback } from "react";
import { MinimalBoard } from "shared/datamodel/schemas";
import { boardsSectionsAtom } from "state-atoms/boards-atom";

export function useBoardsSections() {
  const [boardsSections, setBoardsSections] = useAtom(boardsSectionsAtom);

  const updateSection = useCallback(
    (sectionId: string, boards: MinimalBoard[]) => {
      setBoardsSections((previous) => ({
        ...previous,
        [sectionId]: { boards, id: sectionId },
      }));
    },
    [setBoardsSections]
  );

  const addBoard = useCallback(
    (board: MinimalBoard, sectionIds: string[]) => {
      setBoardsSections((previous) => {
        const updated = { ...previous };
        sectionIds.forEach((id) => {
          const section = previous[id];
          if (section) {
            updated[id] = {
              ...section,
              boards: [board, ...section.boards],
            };
          }
        });
        return updated;
      });
    },
    [setBoardsSections]
  );

  const removeBoard = useCallback(
    (documentId: string, sectionIds?: string[]) => {
      setBoardsSections((previous) => {
        const updated = { ...previous };
        const ids = sectionIds || Object.keys(previous);
        ids.forEach((id) => {
          const section = previous[id];
          if (section) {
            updated[id] = {
              ...section,
              boards: section.boards.filter((b) => b.documentId !== documentId),
            };
          }
        });
        return updated;
      });
    },
    [setBoardsSections]
  );

  const updateBoard = useCallback(
    (board: MinimalBoard, sectionIds?: string[]) => {
      setBoardsSections((previous) => {
        const updated = { ...previous };
        const ids = sectionIds || Object.keys(previous);
        ids.forEach((id) => {
          const section = previous[id];
          if (section) {
            updated[id] = {
              ...section,
              boards: section.boards.map((b) => (b.documentId === board.documentId ? board : b)),
            };
          }
        });
        return updated;
      });
    },
    [setBoardsSections]
  );

  return {
    boardsSections,
    updateSection,
    addBoard,
    removeBoard,
    updateBoard,
    getSection: useCallback((id: string) => boardsSections[id]?.boards || [], [boardsSections]),
    isSectionInCache: useCallback((id: string) => Object.keys(boardsSections).includes(id), [boardsSections]),
  };
}
