import StyledButton from "../styled-button";
import { openSignupPage } from "utils/signup-utils";

function SignUpButton() {
  return (
    <StyledButton
      title="Sign up free"
      data-testid="new-invite-modal-button"
      onClick={() => openSignupPage("sign_up_button_header_in_board")}
      customStyle={{
        height: "32px",
        width: "110px",
        borderRadius: "6px",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        letterSpacing: "0.124px",
      }}
    />
  );
}

export default SignUpButton;
