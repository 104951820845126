import { useCallback, useState } from "react";
import { atom, useAtom, PrimitiveAtom } from "jotai";
import useSWRMutation from "swr/mutation";
import { useIsCacheExpired } from "./use-is-cache-expired";

interface CacheEntry<TEntity> {
  lastUpdated: Date;
  [key: string]: TEntity | Date;
}

interface Cache<TEntity> {
  [key: string]: CacheEntry<TEntity>;
}

interface CacheConfig<TEntity, TCache extends Cache<TEntity>, TKey, CEntity> {
  cacheAtom: PrimitiveAtom<TCache>;
  fetchById?: (id: string) => Promise<TEntity | null>;
  fetchByIds?: (ids: string[]) => Promise<TEntity[]>;
  entityName: string;
  entityKey: string;
  keyField: TKey;
  createItem?: (item: CEntity) => Promise<TEntity>;
  updateItem?: (item: Partial<TEntity>) => Promise<TEntity>;
  removeItem?: (item: Partial<TEntity>) => Promise<boolean>;
  paginationConfig?: {
    hasPagination: boolean;
    pageAtom: PrimitiveAtom<number>;
    hasMorePagesAtom: PrimitiveAtom<boolean>;
    fetchByPage: (page: number) => Promise<TEntity[]>;
  };
}

const defaultPageAtom = atom(1);
const defaultHasMoreAtom = atom(true);

export function useGenericCache<TEntity, TCache extends Cache<TEntity>, TKey extends keyof TEntity, CEntity>({
  cacheAtom,
  fetchById,
  fetchByIds,
  entityName,
  entityKey,
  keyField,
  createItem,
  updateItem,
  removeItem,
  paginationConfig,
}: CacheConfig<TEntity, TCache, TKey, CEntity>) {
  const [cache, setCache] = useAtom(cacheAtom);
  const [isLoading, setIsLoading] = useState(false);
  const isCacheExpired = useIsCacheExpired();

  const { hasPagination, pageAtom, hasMorePagesAtom, fetchByPage } = paginationConfig ?? {};
  const [page, setPage] = useAtom(pageAtom ?? defaultPageAtom);
  const [hasMore, setHasMore] = useAtom(hasMorePagesAtom ?? defaultHasMoreAtom);

  // Existing SWR mutations
  const { trigger: fetchEntitiesByIds } = useSWRMutation<TEntity[], Error, string, string[]>(
    `${entityName}-by-ids`,
    async (_key: string, { arg: ids }: { arg: string[] }) => {
      if (fetchByIds) {
        return fetchByIds(ids);
      }
      return [];
    }
  );

  const { trigger: fetchEntitiesById } = useSWRMutation<TEntity | null, Error, string, string>(
    `${entityName}-by-id`,
    async (_key: string, { arg: id }: { arg: string }) => {
      const item = fetchById && (await fetchById(id));
      return item ?? null;
    }
  );

  const createEntity = useCallback(
    async ({ item }: { item: CEntity }) => {
      if (!createItem) throw new Error("Create operation not configured");
      return createItem(item);
    },
    [createItem]
  );

  const updateEntity = useCallback(
    async ({ item }: { item: Partial<TEntity> }) => {
      if (!updateItem) throw new Error("Update operation not configured");
      return updateItem(item);
    },
    [updateItem]
  );

  const deleteEntity = useCallback(
    async ({ item }: { item: Partial<TEntity> }) => {
      if (!removeItem) throw new Error("Delete operation not configured");
      return removeItem(item);
    },
    [removeItem]
  );

  const { trigger: fetchEntitiesByPage } = useSWRMutation<TEntity[], Error, string, number>(
    `${entityName}-by-page`,
    async (_key: string, { arg: page }: { arg: number }) => {
      if (!fetchByPage) throw new Error("Fetch by page not configured");
      return fetchByPage(page);
    }
  );

  const filterMissingIds = useCallback(
    (ids: string[]): string[] => {
      return ids.filter((id) => {
        const cachedItem = cache[id];
        return !cachedItem || isCacheExpired(cachedItem.lastUpdated);
      });
    },
    [cache, isCacheExpired]
  );

  const updateCache = useCallback(
    (items: TEntity[]): void => {
      const now = new Date();
      const newCacheEntries = items.reduce(
        (acc, item) => ({
          ...acc,
          [item[keyField] as string]: {
            [entityKey]: item,
            lastUpdated: now,
          },
        }),
        {} as TCache
      );
      setCache((previousCache) => ({ ...previousCache, ...newCacheEntries }));
    },
    [setCache, entityKey, keyField]
  );

  const removeFromCache = useCallback(
    (item: Partial<TEntity>): void => {
      setCache((previousCache) => {
        const newCache = { ...previousCache };
        delete newCache[item[keyField] as string];
        return newCache;
      });
    },
    [setCache, keyField]
  );

  const createItemCache = useCallback(
    async (newItem: CEntity): Promise<TEntity | null> => {
      setIsLoading(true);
      try {
        const createdItem = await createEntity({ item: newItem });
        updateCache([createdItem]);
        return createdItem;
      } catch (error) {
        console.error("Failed to create item:", error);
        return null;
      } finally {
        setIsLoading(false);
      }
    },
    [createEntity, updateCache]
  );

  const updateItemCache = useCallback(
    async (item: Partial<TEntity>): Promise<TEntity | null> => {
      setIsLoading(true);
      try {
        const updatedItem = await updateEntity({ item });
        updateCache([updatedItem]);
        return updatedItem;
      } catch (error) {
        console.error("Failed to update item:", error);
        return null;
      } finally {
        setIsLoading(false);
      }
    },
    [updateEntity, updateCache]
  );

  const removeItemCache = useCallback(
    async (item: Partial<TEntity>): Promise<boolean> => {
      setIsLoading(true);
      try {
        const success = await deleteEntity({ item });
        if (success) {
          removeFromCache(item);
        }
        return success;
      } catch (error) {
        console.error("Failed to delete item:", error);
        return false;
      } finally {
        setIsLoading(false);
      }
    },
    [deleteEntity, removeFromCache]
  );

  const getValidCachedItems = useCallback((): TEntity[] => {
    return Object.values(cache)
      .filter((entry) => !isCacheExpired(entry.lastUpdated))
      .map((entry) => entry[entityKey] as TEntity);
  }, [cache, isCacheExpired, entityKey]);

  const getById = useCallback(
    async (id: string): Promise<TEntity | null> => {
      const [missingId] = filterMissingIds([id]);
      let fetchedItem: TEntity | null = null;

      if (missingId) {
        fetchedItem = await fetchEntitiesById(missingId);
        if (fetchedItem) {
          updateCache([fetchedItem]);
        }
      }

      return fetchedItem ?? null;
    },
    [filterMissingIds, fetchEntitiesById, updateCache]
  );

  const getByIds = useCallback(
    async (ids: string[]): Promise<TEntity[]> => {
      const missingIds = filterMissingIds(ids);
      let fetchedItems: TEntity[] = [];

      if (missingIds.length > 0) {
        fetchedItems = await fetchEntitiesByIds(missingIds);
        if (fetchedItems.length > 0) {
          updateCache(fetchedItems);
        }
      }

      return [
        ...ids.map((id) => cache[id]?.[entityKey]).filter((item): item is TEntity => item !== undefined),
        ...fetchedItems,
      ];
    },
    [filterMissingIds, fetchEntitiesByIds, updateCache, cache, entityKey]
  );

  const getByPage = useCallback(async (): Promise<TEntity[]> => {
    if (!fetchByPage) {
      throw new Error("Fetch by page not configured");
    }
    const cachedItems = getValidCachedItems();

    if (!hasMore) {
      return cachedItems;
    }

    const fetchedItems = await fetchEntitiesByPage(page);

    if (fetchedItems.length === 0) {
      setHasMore(false);
      return cachedItems;
    }

    setPage(page + 1);
    updateCache(fetchedItems);

    const uniqueItems = [...cachedItems, ...fetchedItems].reduce((acc, item) => {
      acc[item[keyField] as string] = item;
      return acc;
    }, {} as Record<string, TEntity>);

    return Object.values(uniqueItems);
  }, [
    fetchByPage,
    getValidCachedItems,
    hasMore,
    fetchEntitiesByPage,
    page,
    setHasMore,
    setPage,
    updateCache,
    keyField,
  ]);

  const getByPageNumber = useCallback(
    async (pageNumber: number): Promise<TEntity[]> => {
      if (!fetchByPage) {
        throw new Error("Fetch by page not configured");
      }
      const cachedItems = getValidCachedItems();

      if (!hasMore) {
        return cachedItems;
      }

      const fetchedItems = await fetchEntitiesByPage(pageNumber);

      updateCache(fetchedItems);

      const uniqueItems = [...cachedItems, ...fetchedItems].reduce((acc, item) => {
        acc[item[keyField] as string] = item;
        return acc;
      }, {} as Record<string, TEntity>);

      return Object.values(uniqueItems);
    },
    [fetchByPage, getValidCachedItems, hasMore, fetchEntitiesByPage, updateCache, keyField]
  );

  const getItem = useCallback(
    async (id: string): Promise<TEntity | null> => {
      setIsLoading(true);
      const cachedItem = cache[id];
      if (cachedItem && !isCacheExpired(cachedItem.lastUpdated)) {
        setIsLoading(false);
        return cachedItem[entityKey] as TEntity;
      }

      const fetchedItem = await getById(id);
      setIsLoading(false);
      return fetchedItem;
    },
    [cache, getById, entityKey, isCacheExpired]
  );

  const getItems = useCallback(
    async (input?: string[]): Promise<TEntity[]> => {
      setIsLoading(true);
      try {
        if (Array.isArray(input)) {
          return await getByIds(input);
        } else if (hasPagination) {
          return await getByPage();
        }
        return [];
      } catch {
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [getByIds, getByPage, hasPagination]
  );

  const getItemsByPage = useCallback(
    async (pageNumber: number): Promise<TEntity[]> => {
      setIsLoading(true);
      try {
        return await getByPageNumber(pageNumber);
      } catch {
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [getByPageNumber]
  );

  return {
    getItem,
    getItems,
    getItemsByPage,
    isLoading,
    hasMore,
    updateCache,
    createItemCache,
    updateItemCache,
    removeItemCache,
    getValidCachedItems,
  };
}
