import { projectCacheAtom } from "state-atoms";
import { Project, ProjectCache } from "shared/datamodel/schemas/project";
import { createProjectNew, deleteProjectNew, editProjectNew, getProjectsByIds } from "frontend/api";
import { useGenericCache } from "./use-general-cache";
import { useTeamsWithProjects } from "./use-teams-with-projects";
import { useCallback } from "react";

export function useProjectsCache() {
  const { addProject, updateProject, deleteProject } = useTeamsWithProjects();
  const { getItems, isLoading, createItemCache, removeItemCache, updateItemCache } = useGenericCache<
    Project,
    ProjectCache,
    "id",
    { name: string; teamId: number }
  >({
    cacheAtom: projectCacheAtom,
    fetchByIds: getProjectsByIds,
    entityName: "projects",
    entityKey: "project",
    keyField: "id",
    createItem: async (item: { name: string; teamId: number }) => {
      const project = await createProjectNew(item);
      addProject(project.teamId, project);
      return project;
    },
    removeItem: async (item: Partial<Project>) => {
      if (item.id && item.teamId) {
        await deleteProjectNew(item.id);
        deleteProject(item.teamId, item.id);
        return true;
      }
      return false;
    },
    updateItem: async (item: Partial<Project>) => {
      if (!item.teamId) {
        throw new Error("Team ID is required");
      }
      const project = await editProjectNew({ ...item, projectId: Number(item.id) });
      updateProject(item.teamId, Number(item.id), item);
      if (!project) throw new Error("Failed to update team");
      return project;
    },
  });

  const leaveProject = useCallback(
    async (item: Partial<Project>) => {
      if (item.id && item.teamId && (await updateItemCache(item))) {
        deleteProject(item.teamId, item.id);
      }
    },
    [deleteProject, updateItemCache]
  );

  return {
    getProjects: getItems,
    isLoading,
    createProject: createItemCache,
    removeProject: removeItemCache,
    updateProject: updateItemCache,
    leaveProject,
  };
}
