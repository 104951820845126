import { RefObject, useEffect, useMemo, useState } from "react";
import type { User } from "shared/datamodel/schemas/user";
import type { BubbleFieldMutation } from "../../types";
import { useDebounce } from "frontend/hooks/debounce";
import { useUsersCache } from "frontend/hooks/caching/use-users-cache";
import { userAtom } from "state-atoms/users-atoms";
import { atom, useAtom, useAtomValue } from "jotai";
import { useFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";
import tracking from "frontend/tracking";

const baseUsersAtom = atom<{ users: User[]; isLoaded: boolean }>({ users: [], isLoaded: false });

const FIRST_PAGE = 1;

export function useInviteUsersSelect(
  inputRef: RefObject<HTMLInputElement>,
  usersDropdownRef: RefObject<HTMLDivElement>,
  existingValues: Set<string>,
  onChange: (value: BubbleFieldMutation) => void,
  handleBlur: () => void,
  handleFocus: () => void,
  handleInput: (e: React.FormEvent<HTMLInputElement>) => void
) {
  const [inputValue, setInputValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleUserClick = (user: User) => {
    if (existingValues.has(user.email)) {
      tracking.trackAnalyticsEvent("invite_users_select_clicked", {
        existing_on_list: true,
      });
      return;
    }
    tracking.trackAnalyticsEvent("invite_users_select_clicked", {
      existing_on_list: false,
    });
    setShowDropdown(false);
    onChange({ value: user.email, thumbnail: user.thumbnail, displayValue: user.name, action: "add" });
    setInputValue("");
    setSelectedIndex(-1);
    if (inputRef.current) {
      inputRef.current.value = "";
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const handleCustomBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (usersDropdownRef?.current && usersDropdownRef.current.contains(e.relatedTarget as Node)) {
      return;
    }
    handleBlur();
    setInputValue(e.currentTarget.value);
    setSelectedIndex(-1);
  };

  const handleCustomInput = (e: React.FormEvent<HTMLInputElement>) => {
    setShowDropdown(true);
    handleInput(e);
    setInputValue(e.currentTarget.value);
    setSelectedIndex(-1);
  };

  const handleCustomFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    handleFocus();
    setInputValue(e.currentTarget.value);
  };

  const handleCustomKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, currentUsers: User[]) => {
    if (e.key === "ArrowDown" && currentUsers.length > 0) {
      e.preventDefault();
      setSelectedIndex((previous) => (previous < currentUsers.length - 1 ? previous + 1 : previous));
    } else if (e.key === "ArrowUp" && currentUsers.length > 0) {
      e.preventDefault();
      setSelectedIndex((previous) => (previous > 0 ? previous - 1 : previous));
    } else if (e.key === "Enter" && selectedIndex >= 0 && currentUsers[selectedIndex]) {
      e.preventDefault();
      e.stopPropagation();
      handleUserClick(currentUsers[selectedIndex]);
    } else if (["Tab", ","].includes(e.key) && e.currentTarget.value) {
      setInputValue("");
      setSelectedIndex(-1);
    }
  };

  return {
    inputValue,
    setInputValue,
    handleUserClick,
    handleCustomBlur,
    handleCustomInput,
    handleCustomKeyDown,
    handleCustomFocus,
    showDropdown,
    setShowDropdown,
    selectedIndex,
  };
}

function filterCurrentUser(users: User[], currentUser: User | null) {
  if (!currentUser) {
    return users;
  }
  return users.filter((user) => user.id !== currentUser.id);
}

function getEncodedInputValue(inputValue: string) {
  try {
    return encodeURIComponent(inputValue);
  } catch {
    return inputValue;
  }
}

export function useInviteUsersSelectState(inputValue: string, showDropdown: boolean, isInputFieldFocused: boolean) {
  const isInviteUsersAutocompleteEnabled = useFeatureFlag("invite-users-autocomplete");
  const debouncedInputValue = useDebounce(inputValue, 500);
  const [baseUsers, setBaseUsers] = useAtom(baseUsersAtom);
  const { searchUsersByName, users, getUsersByPage } = useUsersCache();
  const user = useAtomValue(userAtom);
  const [currentUsers, setCurrentUsers] = useState<User[]>(users || []);

  useEffect(() => {
    const fetchUsers = () => {
      if (!debouncedInputValue || !isInviteUsersAutocompleteEnabled) {
        setCurrentUsers([]);
        return;
      }
      searchUsersByName(getEncodedInputValue(debouncedInputValue));
    };
    fetchUsers();
  }, [debouncedInputValue, isInviteUsersAutocompleteEnabled]);

  useEffect(() => {
    if (users) {
      setCurrentUsers(users);
    }
  }, [users]);

  useEffect(() => {
    const loadUsers = async () => {
      const users = await getUsersByPage(FIRST_PAGE);
      setBaseUsers({ users, isLoaded: true });
    };
    if (isInviteUsersAutocompleteEnabled && !baseUsers.isLoaded) {
      loadUsers();
    }
  }, [isInviteUsersAutocompleteEnabled, baseUsers.isLoaded]);

  const filteredUsers = useMemo(() => {
    let usersToReturn = [];

    if (debouncedInputValue) {
      usersToReturn = currentUsers;
    } else {
      usersToReturn = baseUsers.users;
    }

    return filterCurrentUser(usersToReturn, user);
  }, [debouncedInputValue, currentUsers, baseUsers, user]);

  const showInviteUsersSelect =
    isInviteUsersAutocompleteEnabled && showDropdown && isInputFieldFocused && filteredUsers.length > 0;

  return { showInviteUsersSelect, users: filteredUsers };
}
