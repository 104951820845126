import { FormEvent, KeyboardEvent, useCallback, useEffect, useRef, useState, RefObject } from "react";
import classNames from "classnames";
import { isEmail } from "utils/string-utils";
import style from "./tags.module.css";

type EditableTagProps = {
  initialValue: string;
  onComplete: (value: string, navigationKey?: "ArrowLeft" | "ArrowRight" | "Input") => void;
  onCancel: () => void;
  mainInputRef: RefObject<HTMLInputElement>;
  cursorAtStart?: boolean;
  rounded?: boolean;
};

export function EditableTag({
  initialValue,
  onComplete,
  onCancel,
  mainInputRef,
  cursorAtStart,
  rounded,
}: EditableTagProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const measureSpanRef = useRef<HTMLSpanElement>(null);
  const [currentValue, setCurrentValue] = useState(initialValue);
  const isCurrentValid = isEmail(currentValue);

  const updateWidth = useCallback((text: string) => {
    if (containerRef.current && measureSpanRef.current) {
      measureSpanRef.current.textContent = text || "\u200B";
      const contentWidth = measureSpanRef.current.offsetWidth;
      const padding = 16;
      const gap = 8;
      const width = contentWidth + padding + gap;
      containerRef.current.style.width = `${width}px`;
    }
  }, []);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const input = e.currentTarget;
    const cursorPosition = input.selectionStart;
    const textLength = input.value.length;

    // Handle right arrow at end of input
    if (e.key === "ArrowRight" && cursorPosition === textLength) {
      e.preventDefault();
      onComplete(currentValue, "ArrowRight");
      return;
    }

    // Handle left arrow at start of input
    if (e.key === "ArrowLeft" && cursorPosition === 0) {
      e.preventDefault();
      onComplete(currentValue, "ArrowLeft");
      return;
    }

    if (
      e.key === "Enter" ||
      e.key === "," ||
      e.key === "Tab" ||
      (e.key === " " && cursorPosition === textLength && e.currentTarget.value.trim().length > 0)
    ) {
      e.preventDefault();
      onComplete(currentValue.trim(), "Input");
    } else if (e.key === "Escape") {
      e.preventDefault();
      onCancel();
    } else if (e.key === "Backspace" && !currentValue) {
      e.preventDefault();
      onComplete("");
      setTimeout(() => {
        mainInputRef.current?.focus();
      }, 0);
    } else if (e.key === "Delete" && !currentValue) {
      e.preventDefault();
      onComplete("");
      setTimeout(() => {
        mainInputRef.current?.focus();
      }, 0);
    }
  };

  const handleInput = (e: FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setCurrentValue(value);
    updateWidth(value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // Only complete edit if we're not focusing another element in our component
    if (!containerRef.current?.contains(e.relatedTarget) && e.relatedTarget !== mainInputRef.current) {
      if (!currentValue) {
        onComplete("", "ArrowLeft");
        return;
      }
      onComplete(currentValue);
    }
  };

  const handleContainerClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      const length = inputRef.current.value.length;
      if (cursorAtStart) {
        inputRef.current.setSelectionRange(0, 0);
      } else {
        inputRef.current.setSelectionRange(length, length);
      }
      updateWidth(initialValue);
    }
  }, [initialValue, updateWidth, cursorAtStart]);

  return (
    <div
      ref={containerRef}
      className={classNames(style.editableTagContainer, {
        [style.invalid]: !isCurrentValid,
        [style.rounded]: rounded,
      })}
      onClick={handleContainerClick}
    >
      <input
        ref={inputRef}
        className={classNames(style.tagInput, {
          [style.invalid]: !isCurrentValid,
        })}
        value={currentValue}
        onKeyDown={handleKeyDown}
        onInput={handleInput}
        onBlur={handleBlur}
        onClick={(e) => e.stopPropagation()}
      />
      <span ref={measureSpanRef} className={style.hiddenSpan} />
    </div>
  );
}
