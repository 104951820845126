import { logout } from "frontend/api";
import classNames from "classnames";
import { useOnClickOutside } from "usehooks-ts";
import useStateValue from "frontend/state/value";
import { mondayURL } from "frontend/utils/url-utils";
import { useRouter } from "next/router";
import { useRef, useState } from "react";
import style from "./profile-new.module.css";
import { openCustomerPortal } from "frontend/billingUtils";
import { SyncService } from "frontend/services/syncService";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { useClientInfo } from "frontend/subscriptions";
import { Floater } from "frontend/ui-components/floaters/floater";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import { SettingsIcon } from "frontend/icons/settings-icon";
import { BillingIcon } from "frontend/icons/billing";
import { MembersIcon } from "frontend/icons/members";
import { LogoutIcon } from "frontend/icons/logout";
import Modal from "frontend/modal/modal";
import AccountMembers from "frontend/modals/collaboration/account-members";
import EditSeatsModal from "frontend/modals/edit-seats-modal";

export default function ProfileNew({
  isBoardHeader,
  syncService,
}: {
  isBoardHeader: boolean;
  syncService?: SyncService<RW>;
}) {
  const reps = syncService?.getReplicache();
  const clientID = reps?.clientID;
  const info = useClientInfo(reps, clientID);

  const [{ user }] = useStateValue();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showAccountMembers, setShowAccountMembers] = useState(false);
  const [showEditSeatsModal, setShowEditSeatsModal] = useState(false);
  const profileRef = useRef(null);

  const menuRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  useOnClickOutside(menuRef, () => {
    setShowProfileMenu(false);
  });

  function profileClicked() {
    setShowProfileMenu(true);
  }

  function showMyProfile() {
    if (!user?.account) {
      return;
    }
    const url = mondayURL(user.account.slug, `p/users/${user.id}`);
    window.open(url, "__blank")?.focus();
    setShowProfileMenu(false);
  }

  function showSettings() {
    if (!user || !user.account) {
      return;
    }
    tracking.trackAnalyticsEvent("settings_button_clicked", {
      category: consts.TRACKING_CATEGORY.ADMIN_ACTION,
    });
    const url = mondayURL(user.account.slug, `admin`);
    window.open(url, "__blank")?.focus();
    setShowProfileMenu(false);
  }

  function handleLogout() {
    tracking.trackAnalyticsEvent("logout_button_clicked", {
      category: consts.TRACKING_CATEGORY.ADMIN_ACTION,
    });
    logout().then(() => {
      if (!user || !user.account) {
        return;
      }
      const url = mondayURL(user.account.slug, `p/whiteboard/logout`);
      router.push(url);
    });
  }

  return (
    <div>
      {user && (
        <div
          className={classNames(style.profile, { [style.border]: isBoardHeader })}
          ref={profileRef}
          onClick={profileClicked}
        >
          <img src={user.thumbnail} style={{ borderColor: info?.userInfo.color }} alt="" />
        </div>
      )}
      {showProfileMenu && (
        <>
          <Floater
            relativeTo={profileRef}
            side={"bottom"}
            ref={menuRef}
            extraStyle={{
              zIndex: "10000000",
              filter: "drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.20))",
              marginRight: "20px",
            }}
          >
            <div className={style.teamMenu}>
              {user?.isAdmin && (
                <>
                  <span
                    data-testid="profile"
                    className={style.item}
                    onClick={() => {
                      showMyProfile();
                    }}
                  >
                    <img
                      src={user.thumbnail}
                      style={{ borderColor: info?.userInfo.color }}
                      alt=""
                      className={style.thumbnail}
                    />
                    {`${user.name} profile`}
                  </span>
                  <span
                    data-testid="settings"
                    className={classNames(style.item, style.settings)}
                    onClick={() => {
                      showSettings();
                    }}
                  >
                    <SettingsIcon />
                    {"Settings"}
                  </span>
                  <span
                    data-testid="billing"
                    className={style.item}
                    onClick={() => {
                      tracking.trackAnalyticsEvent("billing_button_clicked", {
                        category: consts.TRACKING_CATEGORY.ADMIN_ACTION,
                      });
                      setShowProfileMenu(false);
                      openCustomerPortal({ type: "admin" });
                    }}
                  >
                    <BillingIcon />
                    {"Billing"}
                  </span>
                </>
              )}
              <>
                <span
                  data-testid="account members"
                  className={style.item}
                  onClick={() => {
                    tracking.trackAnalyticsEvent("account_members_button_clicked", {
                      category: consts.TRACKING_CATEGORY.ADMIN_ACTION,
                    });
                    setShowAccountMembers(true);
                    setShowProfileMenu(false);
                  }}
                >
                  <MembersIcon />
                  {"Account members"}
                </span>
                <span
                  data-testid="logout"
                  className={style.item}
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  <LogoutIcon />
                  {"Logout"}
                </span>
              </>
            </div>
          </Floater>
        </>
      )}
      {showAccountMembers && (
        <Modal>
          <AccountMembers
            title={"View account members"}
            onDismiss={() => setShowAccountMembers(false)}
            onEditSeats={() => setShowEditSeatsModal(true)}
          />
        </Modal>
      )}

      {showEditSeatsModal && (
        <Modal>
          <EditSeatsModal onDismiss={() => setShowEditSeatsModal(false)} />
        </Modal>
      )}
    </div>
  );
}
