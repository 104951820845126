import { getTeam, createTeamNew, deleteTeamNew, editTeamNew } from "frontend/api";
import { teamCacheAtom } from "state-atoms";
import { Team, TeamCache } from "shared/datamodel/schemas/team";
import { useGenericCache } from "./use-general-cache";
import { useTeamsWithProjects } from "./use-teams-with-projects";
import { useCallback } from "react";

export function useTeamsCache() {
  const { addTeam, deleteTeam, updateTeam } = useTeamsWithProjects();
  const { getItems, isLoading, createItemCache, removeItemCache, updateItemCache } = useGenericCache<
    Team,
    TeamCache,
    "id",
    { name: string }
  >({
    cacheAtom: teamCacheAtom,
    fetchByIds: async (ids: string[]) => {
      const teams = await Promise.all(ids.map((id) => getTeam(id)));
      return teams;
    },
    entityName: "teams",
    entityKey: "team",
    keyField: "id",
    createItem: async (item: { name: string }) => {
      const team = await createTeamNew(item);
      addTeam(team);
      return team;
    },
    removeItem: async (item: Partial<Team>) => {
      if (item.id) {
        await deleteTeamNew(item.id);
        deleteTeam(item.id);
        return true;
      }
      return false;
    },
    updateItem: async (item: Partial<Team>) => {
      const team = await editTeamNew({ ...item, teamId: Number(item.id) });
      updateTeam(Number(item.id), item);
      if (!team) throw new Error("Failed to update team");
      return team;
    },
  });

  const leaveTeam = useCallback(
    async (item: Partial<Team>) => {
      if (item.id && (await updateItemCache(item))) {
        deleteTeam(Number(item.id));
      }
    },
    [deleteTeam, updateItemCache]
  );

  return {
    getTeams: getItems,
    isLoading,
    createTeam: createItemCache,
    removeTeam: removeItemCache,
    updateTeam: updateItemCache,
    leaveTeam,
  };
}
